import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Div from '../layouts/Div'

import Backend from "../../../utils/Backend"

import Notify from "../../../utils/Notify"
import General from "../../../utils/General"

import Select from '../common/Select';

const STATUSES = [
  {
    label: "New",
    value: "new"
  },
  {
    label: "Replied",
    value: "replied"
  },
  {
    label: "Closed",
    value: "closed"
  },
  {
    label: "Live",
    value: "live"
  },
]

export default class PartnerLeadModal extends React.Component {
  constructor(props){
    super(props)
    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      leadData: props.lead
    }
  }

  _onUpdateClicked(){
    let {
      leadData,
    } = this.state

    this.setState({ loading: true })
    Backend.updatePartnerLead(leadData)
    .then(leadData => {
      Notify.success(`Lead successfully updated!`)
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      loading,
      leadData,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Lead</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Div
            disabled={loading}
            spinner={false}
          >
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Status</label>
              <div className="col-lg-9 my-auto">
                <Select
                  value={STATUSES.find(status => status.value === leadData.status)}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  name="Staus"
                  placeholder="Please Select An Option"
                  onChange={option => {
                    leadData.status = option.value
                    this.setState({ leadData })
                  }}
                  options={STATUSES}
                />
              </div>
            </div>
          </Div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._onUpdateClicked()}
          >
            Update
          </button>

        </Modal.Footer>

      </Modal>
    )
  }
}

PartnerLeadModal.defaultProps = {
    loading: false,
}
