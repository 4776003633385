import React from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import LeftMenu from "./components/LeftMenu";
import MobileHeader from "./components/MobileHeader";
import Footer from "./components/Footer";
import Header from "./components/Header";
import SubHeader from "./components/SubHeader";
import QuickPanel from "./components/QuickPanel";

import Dashboard from "./Dashboard";
import Live from "./Live";
import Demo from "./Demo";
import Archived from "./Archived";
import TemplateSites from "./TemplateSites";
import Packages from "./Packages";
import Partners from "./Partners";
import PartnerDetail from "./PartnerDetail";
import Reports from "./Reports";
import Domains from "./Domains";
import Submissions from "./Submissions";
import Coupons from "./Coupons";
import Companies from "./Companies";
import Templates from "./Templates";
import DomainSettings from "./DomainSettings";
import PartnerSites from "./PartnerSites";
import Package from "./Package";
import Team from "./Team";
import Conversations from "./Conversations";

import PropagationAlert from "./components/alert/PropagationAlert";
import TransferDomainAlert from "./components/alert/TransferDomainAlert";

import Settings from "./Settings";
import StripeConnect from "./StripeConnect";
import PartnerLeads from "./PartnerLeads";

import AuthManager from "../utils/AuthManager";
import Notify from "../utils/Notify";
import Event from "../utils/Event";

export default class App extends React.Component {
  componentDidMount() {
    this._setupWebsocket();
    Event.on("SHOW_MESSAGE", (data) => {
      this._showMessageNotification(data);
    });
  }

  _showMessageNotification(data) {
    Notify.message(data, () => {
      window.location = `/chats?chat_id=${data.conversation}`;
    });
  }

  _setupWebsocket(attemptNo = 1) {
    let baseUrl = window.Api.Base;
    let protocol = baseUrl.indexOf("https://") > -1 ? "wss://" : "ws://";
    baseUrl = baseUrl.replace("https://", "").replace("http://", "");

    let url = protocol + baseUrl + "/ws/notifications";

    this.chatSocket = new WebSocket(url, [`Bearer${AuthManager.accessToken}`]);

    this.chatSocket.onopen = (e) => {
      this.setState({ loading: false });
    };

    this.chatSocket.onmessage = (e) => {
      const data = JSON.parse(e.data);

      if (
        data.sender &&
        data.sender.user?.id != AuthManager.currentUser.user.id
      ) {
        Event.emit("MESSAGE", data);
      }
    };

    this.chatSocket.onerror = (e) => {
      console.log("ERROR", e);
    };
    this.chatSocket.onclose = (e) => {
      setTimeout(() => {
        console.log("Reattempting connection to websocket", e);
        this._setupWebsocket(attemptNo + 1);
      }, 3000 * attemptNo);
      console.error(
        "Chat socket closed unexpectedly, will re attempt to connect"
      );
    };
  }

  render() {
    return (
      <>
        <MobileHeader />

        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <LeftMenu />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />

              {/* begin:: Content */}
              <div
                className="kt-content  kt-grid__item kt-grid__item--fluid"
                id="kt_content"
              >
                <div
                  className={
                    window.location.pathname == "/leads" ||
                    window.location.pathname == "/leads-mobile"
                      ? "board-container metronic-7-0-5 kanban-v2 mt-8 "
                      : "kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid"
                  }
                >
                  {AuthManager.isPartner() && (
                    <>
                      <PropagationAlert />
                      <TransferDomainAlert />
                    </>
                  )}

                  <Switch>
                    {/* }<Route exact path="/" component={Overview} /> */}
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/live" component={Live} />
                    <Route exact path="/demo" component={Demo} />
                    <Route exact path="/archived" component={Archived} />
                    <Route exact path="/templates" component={TemplateSites} />
                    <Route exact path="/packages" component={Packages} />
                    <Route exact path="/package" component={Package} />
                    <Route exact path="/package/:package_id" component={Package} />
                    <Route exact path="/partners" component={Partners} />
                    <Route
                      exact
                      path="/partners/:partner_id"
                      component={PartnerDetail}
                    />
                    <Route
                      exact
                      path="/partner-sites"
                      component={PartnerSites}
                    />
                    <Route exact path="/reports" component={Reports} />
                    <Route exact path="/domains" component={Domains} />
                    <Route exact path="/coupons" component={Coupons} />
                    <Route exact path="/leads" component={Submissions} />
                    <Route exact path="/clients" component={Companies} />
                    <Route exact path="/settings" component={Settings} />
                    <Route
                      exact
                      path="/stripe-connect"
                      component={StripeConnect}
                    />
                    <Route exact path="/template-sites" component={Templates} />
                    <Route exact path="/team" component={Team} />
                    <Route path="/domain" component={DomainSettings} />
                    <Route exact path="/chats" component={Conversations} />
                    <Route exact path="/partner-leads" component={PartnerLeads} />
                    <Redirect to="/404" />
                  </Switch>
                </div>
                {/*  end:: Content  */}
              </div>
              {/*  begin:: Footer  */}
              <Footer />
              {/*  end:: Footer  */}
            </div>
          </div>
          {/*  end:: Page  */}
          <QuickPanel />
          {/*  begin::Scrolltop  */}
          <div id="kt_scrolltop" className="kt-scrolltop">
            <i className="fa fa-arrow-up" />
          </div>
        </div>
      </>
    );
  }
}
