import React from "react"

import Chart from './Chart'

export default class GraphStatCard extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      stat: props.stat
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      stat,
    } = this.state

    return (
      <div className="col-lg-4">
        <div className={`kt-portlet kt-portlet--border-bottom-dark`}>
          <div className="kt-portlet__body kt-portlet__body--fluid">
            <div className="kt-widget26">
              <div className="kt-widget26__content">
                <span className="kt-widget26__number">{ stat.title }</span>
                <span className="kt-widget26__desc">{ stat.subtitle }</span>
              </div>
              <div className="kt-widget26__chart stat_chart"
                style={{ height: 100, width: "100%" }}
              >
                <Chart
                  type="line"
                  data={stat.data}
                  color={stat.color}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

GraphStatCard.defaultProps = {
  title: "Title",
  subtitle: "Subtitle",
  type: "brand"
}
