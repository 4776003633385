import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import DomainsTable from './components/tables/DomainsTable'

export default class Domains extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    return (
      <div className="row">
        <DomainsTable
          title="Domains"
        />
      </div>
    )
  }
}
