import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Select from '../common/Select'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"

const ROLES = [
  {
    label: "Manager",
    value: "admin"
  },
  {
    label: "Staff",
    value: "staff"
  },
]

export default class CompanyMemberAddModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      loading: false,
      error: null,
      data: {
        role: "staff",
        user: {}
      }
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _handleChangeUser(e) {
    let data = this.state.data
    data["user"][e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _isFormValid(){
    let {
      role,
    } = this.state.data

    let {
      email,
      first_name,
      last_name,
      password,
      confirm_password,
    } = this.state.data.user

    let error = null
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if(first_name == null || first_name.length === 0){
      error = "Please enter a first name"
    }

    else if(last_name == null || last_name.length === 0){
      error = "Please enter a last name"
    }

    else if(email == null || email.length === 0){
      error = "Please enter an email"
    }

    else if(reg.test(email) == false){
      error ='Please enter a valid email address'
    }

    else if(password == null || password.length === 0){
      error = "Please enter a valid password"
    }

    else if(password != confirm_password){
      error = "Passwords must match"
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddCompanyMember(){
    if(!this._isFormValid()){
      return
    }
    this._addCompanyMember()
  }

  _addCompanyMember(){
    let {
      data
    } = this.state

    this.setState({ loading: true })
    Backend.addCompanyMember(data)
    .then(companyMember => {
      this.setState({
        loading: false,
        data: {
          role: "staff",
          user: {}
        }
      })
      this.props.onAdded(companyMember)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      loading,
      data,
      error
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { error &&
            <div className="form-group">
              <span className="error-message">{ error }</span>
            </div>
          }

          <div className="form-group row">
            <label className="col-lg-5 col-form-label">First Name</label>
            <div className="col-lg-7 my-auto">
              <input
                type="text"
                name="first_name"
                className="form-control form-control-solid"
                value={ data.user.first_name }
                onChange={e => this._handleChangeUser(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-5 col-form-label">Last Name</label>
            <div className="col-lg-7 my-auto">
              <input
                type="text"
                name="last_name"
                className="form-control form-control-solid"
                value={ data.user.last_name }
                onChange={e => this._handleChangeUser(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-5 col-form-label">Email</label>
            <div className="col-lg-7 my-auto">
              <input
                type="email"
                name="email"
                className="form-control form-control-solid"
                value={ data.user.email }
                onChange={e => this._handleChangeUser(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-5 col-form-label">Password</label>
            <div className="col-lg-7 my-auto">
              <input
                type="password"
                name="password"
                className="form-control form-control-solid"
                value={ data.user.password }
                onChange={e => this._handleChangeUser(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-5 col-form-label">Confirm Password</label>
            <div className="col-lg-7 my-auto">
              <input
                type="password"
                name="confirm_password"
                className="form-control form-control-solid"
                value={ data.user.confirm_password }
                onChange={e => this._handleChangeUser(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-5 col-form-label">Role</label>
            <div className="col-lg-7 my-auto">
              <Select
                value={ROLES.find(role => role.value === data.role)}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                name="role"
                placeholder="Please Select An Option"
                onChange={option => {
                  data.role = option.value
                  this.setState({ data })
                }}
                options={ROLES}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._handleAddCompanyMember()}
          >
            Add Company Member
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
