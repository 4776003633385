import React from 'react'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import BigNumber from "bignumber.js";

import List from './List'
import IdleList from './IdleList'

export default class Board extends React.Component {
  constructor(props) {
    super(props)

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    // convert passed in data into a more efficient hashmap for sorting
    let { lists } = props
    let listIds = lists.map(list => list.id)

    let listsOrder = []

    let listsById = {}

    let cardsById = {}

    lists.forEach((list, index) => {
      list.cards.forEach((card, cardIndex) => {
        cardsById[card.id] = JSON.parse(JSON.stringify(card))
      })

      let data = JSON.parse(JSON.stringify(list))
      delete data.cards

      data.cardIds = list.cards.map(card => card.id)

      listsById[data.id] = data
      listsOrder.push(data.id)
    })

    return {
      listsOrder,
      listsById,
      cardsById,
    }
  }

  _wasMovedOrReordered(result) {
    let { destination, source } = result
    if (!destination) {
      // moved outside of a list
      return false
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      // put back in same place on same list
      return false
    }
    return true
  }

  _reorderLists(result) {
    let { destination, source, draggableId } = result
    let { listsById, listsOrder } = this.state

    let newListsOrder = Array.from(listsOrder)

    newListsOrder.splice(source.index, 1)
    newListsOrder.splice(destination.index, 0, parseInt(draggableId))

    let newPosition = this._getPosition(
      listsById[draggableId],
      listsById[newListsOrder[destination.index - 1]],
      listsById[newListsOrder[destination.index + 1]],
      listsById[listsOrder[0]],
      listsById[listsOrder[listsOrder.length - 1]]
    )

    this.setState({ listsOrder: newListsOrder }, () => {
      this.props.onListReordered(listsById[draggableId], newPosition, listsOrder, destination.index)
    })
  }

  _moveCardWithinList(result, list) {
    let { listsById, cardsById } = this.state
    let { destination, source, draggableId } = result

    let newCardIds = Array.from(list.cardIds)
    newCardIds.splice(source.index, 1)
    newCardIds.splice(destination.index, 0, draggableId)

    let newList = {
      ...list,
      cardIds: newCardIds,
    }

    let newListsById = {
      ...listsById,
      [newList.id]: newList,
    }

    let newPosition = this._getPosition(
      cardsById[draggableId],
      cardsById[newCardIds[destination.index - 1]],
      cardsById[newCardIds[destination.index + 1]],
      cardsById[list.cardIds[0]],
      cardsById[list.cardIds[list.cardIds.length - 1]]
    )

    this.setState({ listsById: newListsById }, () => {
      this.props.onCardMoved(cardsById[draggableId], newList, newPosition, destination.index)
    })
  }

  _moveCardAcrossLists(result, startList, finishList) {
    let { listsById, cardsById } = this.state
    let { destination, source, draggableId } = result

    const startCardIds = Array.from(startList.cardIds)
    startCardIds.splice(source.index, 1)
    let newStartList = {
      ...startList,
      cardIds: startCardIds,
    }

    const finishCardIds = Array.from(finishList.cardIds)
    finishCardIds.splice(destination.index, 0, draggableId)
    let newFinishList = {
      ...finishList,
      cardIds: finishCardIds,
    }

    const newListsById = {
      ...listsById,
      [newStartList.id]: newStartList,
      [newFinishList.id]: newFinishList,
    }

    let newPosition = this._getPosition(
      cardsById[draggableId],
      cardsById[finishCardIds[destination.index - 1]],
      cardsById[finishCardIds[destination.index + 1]],
      cardsById[finishList.cardIds[0]],
      cardsById[finishList.cardIds[finishList.cardIds.length - 1]]
    )

    this.setState({ listsById: newListsById }, () => {
      this.props.onCardMoved(cardsById[draggableId], newFinishList, newPosition, destination.index)
    })
  }

  _onDragEnd(result) {
    let { listsById } = this.state
    let { destination, source, type } = result

    if (!this._wasMovedOrReordered(result)) {
      return
    }

    let startList = listsById[source.droppableId]
    let finishList = listsById[destination.droppableId]

    if (type === 'list') {
      this._reorderLists(result)
    }
    else if (startList === finishList) {
      this._moveCardWithinList(result, startList)
    }
    else{
      this._moveCardAcrossLists(result, startList, finishList)
    }
  }

  _getPosition(obj, beforeObj, afterObj, topObj, bottomObj){
    let newPosition = null
    let positionBuffer = 65535
    if(!beforeObj){
      // topObj is null if this ss obj on empty list
      newPosition = BigNumber(topObj?.position || positionBuffer * 2).dividedBy(2)
    }
    else if(!afterObj){
      newPosition = BigNumber(bottomObj.position).plus(positionBuffer)
    }
    else{
      newPosition = BigNumber(beforeObj.position).plus(afterObj.position).dividedBy(2)
    }
    return newPosition.toFixed(8)
  }

  render() {
    let { listsById, cardsById, listsOrder } = this.state
    return (
      <div className="board">
        <DragDropContext onDragEnd={(result) => this._onDragEnd(result)}>
          <Droppable
            droppableId="board"
            direction="horizontal"
            type="list"
          >
            {(provided) => (
              <div
                className="columns-container kanban-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {listsOrder.map((listId, index) => {
                  // parse efficient hashmap back into expected data
                  let list = listsById[listId]
                  list.cards = list.cardIds.map(cardId => cardsById[cardId])

                  return (
                    <List
                      key={list.id}
                      list={list}
                      index={index}
                      onCardClicked={this.props.onCardClicked}
                      onAddCardClicked={(list) => this.props.onAddCardClicked(list)}
                      renderCard={this.props.renderCard}
                      renderHeader={this.props.renderListHeader}
                      onSettingsUpdated={(list) => this.props.onSettingsUpdated(list)}
                      onListDeleted={(list) => this.props.onListDeleted(list)}
                    />
                  )
                })}
                <IdleList
                  index={listsOrder.length+1}
                  onCardClicked={this.props.onCardClicked}
                  onAddCardClicked={this.props.onAddCardClicked}
                  onAddListClicked={(newList) => this.props.onAddListClicked(newList)}
                  renderCard={this.props.renderCard}
                  renderHeader={this.props.renderListHeader}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}
