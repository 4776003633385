import React from "react"
import { withRouter } from "react-router-dom";

import moment from 'moment'

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Website from '../../../utils/Website'
import Currency from '../../../utils/Currency'

import ConfirmModal from '../../components/modal/ConfirmModal'
import TransferModal from '../../components/modal/TransferModal'
import CreateWebsiteModal from '../../components/modal/CreateWebsiteModal'
import EditWebsiteModal from '../../components/modal/EditWebsiteModal'
import CompanyMemberEditModal from '../../components/modal/CompanyMemberEditModal'

import Select from '../common/Select';
import Notify from "../../../utils/Notify";

import { BigNumber } from "bignumber.js";

const ICON = (
  <g stroke="none" strokewidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z" fill="#000000"></path>
      <path d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
  </g>
)

const STATUSES = [
  {
    label: "All",
    value: "null"
  },
  {
    label: "Live",
    value: "live"
  },
  {
    label: "Demo",
    value: "demo"
  },
  {
    label: "Archived",
    value: "shutdown"
  }
]

class PartnersTable extends React.Component {

  constructor(props){
    super(props)


    this.state = {
      endpoint: window.Api.Partners,
      mode: props.mode,
      loading: true,
      filters: [],
      selectedFilter: null,
    }

    this.table = React.createRef()
  }

  componentDidMount(){
    //this._setup()
  }

  _onEditWebsiteClicked(website){
    this.setState({ loadingHash: true })
    let url = Website.getSiteUrl(website) + "?edit=true"
    let editWebsiteTab = window.open();
    Backend.createLoginHash(website)
    .then(hash => {
      editWebsiteTab.location.href = url+"&h="+hash.value
      this.setState({ loadingHash: false })
    })
    .catch(error => {
      editWebsiteTab.location.href = url
      this.setState({ loadingHash: false })
    })
  }

  _onQuickEditWebsiteClicked(website, partner){
    this.setState({
      selectedWebsite: website,
      selectedPartner: partner,
      showQuickEditModal: true
    })
  }

  _toggleStatus(partner){
    if(partner.status === "active"){
      console.log("22", partner)
      partner.status = "banned"
      return Backend.updatePartner(partner)
    }
    else{
      partner.status = "active"
      return Backend.updatePartner(partner)
    }
  }

  _getColumns() {
    return [
      {
        Header: 'Created',
        id: 'created_at',
        accessor: partner => moment(partner.created_at).format('DD MMM YYYY'),
        width: 100,
      },
      {
        Header: 'Name',
        id:'company.name',
        width: 150,
        Cell: rowInfo => {
          let partner = rowInfo.original
          return partner.company.name
        },
      },
      {
        Header: 'Monthly',
        id: 'monthly_license',
        Cell: rowInfo => {
          let partner = rowInfo.original
          let symbol = "€"
          if(partner.currency === "GBP"){
            symbol = "£"
          }
          else if(partner.currency === "USD"){
            symbol = "$"
          }
          return Currency.toCurrency(symbol, partner.monthly_license)
        },
        width: 80,
      },
      {
        Header: 'M & R %',
        id: 'commission',
        Cell: rowInfo => {
          let partner = rowInfo.original
          return BigNumber(partner.commission).multipliedBy(100).toFixed(2) + "%"
        },
        width: 80,
      },
      {
        Header: 'Recurring',
        id: 'recurring',
        Cell: rowInfo => {
          let partner = rowInfo.original
          return Currency.toCurrency("€", partner.recurring)
        },
        width: 90,
      },
      {
        Header: 'Once Off',
        id: 'once_off',
        Cell: rowInfo => {
          let partner = rowInfo.original
          return Currency.toCurrency("€", partner.once_off)
        },
        width: 90,
      },
      {
        Header: 'Live',
        accessor: 'live_companies_no',
        width: 50,
      },
      {
        Header: 'Demo',
        accessor: 'demo_companies_no',
        width: 60,
      },
      {
        Header: 'Last Sale',
        id: 'last_sale_at',
        width: 120,
        accessor: partner => partner.last_sale_at ? moment(partner.last_sale_at).format('DD MMM YYYY') : "-"
      },
      {
        Header: 'Status',
        id: 'status',
        width: 300,
        show: false,
        Cell: rowInfo => {
          let partner = rowInfo.original
          let label = "Active"
          let type = "success"

          if(partner.status === "banned"){
            label = "Suspended"
            type = "warning"
          }

          return (
            <span
              className={`kt-badge kt-badge--${type} kt-badge--inline kt-badge--pill`}
            >
                { label }
            </span>
          )
        }
      },
      {
        Header: 'Actions',
        id: 'status',
        sortable: false,
        Cell: rowInfo => {
          let partner = rowInfo.original
          let website = partner.website
          let { loadingHash } = this.state
          return (
            <span>
                {
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    title="Reports"
                    onClick={() => this.props.history.push(`partners/${partner.company.id}`)}
                  >
                    <i className="la la-bar-chart"/>
                  </a>
                }
                { website &&
                  <>
                  { website.status === "live" &&
                    <a
                      href={ Website.getNativeDomain(website) }
                      className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                      target="_blank"
                      title="View"
                    >
                      <i className="la la-eye"/>
                    </a>
                  }
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                    title="Edit"
                    onClick={() => this._onEditWebsiteClicked(website)}

                  >
                    <i className={`la la-${loadingHash ? "ellipsis-h" : "edit"}`}/>
                  </a>
                  { partner.status === "active" &&
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                      onClick={() => {
                        this._toggleStatus(partner)
                        .then(() => {
                          this.table.current.refresh()
                          Notify.success("Partner successfully suspended!")
                        })
                      }}
                      title={ "Suspend" }
                    >
                      <i className="la la-power-off"/>
                    </a>
                  }
                  { partner.status !== "active" &&
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                      onClick={() => {
                        this._toggleStatus(partner)
                        .then(() => {
                          this.table.current.refresh()
                          Notify.success("Partner successfully activated!")
                        })
                      }}
                      title="Activate"
                    >
                      <i className="la la-power-off"/>
                    </a>
                  }
                  {/*}
                  { website.status == "shutdown" &&
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                      onClick={() => {
                        this._toggleStatus(website)
                        .then(() => {
                          this.table.current.refresh()
                        })
                      }}
                      title="Publish"
                    >
                      <i className="la la-power-off"/>
                    </a>
                  }
                  */}
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                    title="Quick Edit"
                    onClick={() => this._onQuickEditWebsiteClicked(website, partner)}

                  >
                    <i className={`la la-cog`}/>
                  </a>
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                    title="Edit Partner"
                    onClick={() => this.setState({ selectedPartner: partner, showCompanyMemberEditModal: true })}

                  >
                    <i className={`la la-user`}/>
                  </a>
                </>
              }
            </span>
          )
        }
      },
    ]
  }

  _renderDetail(partner){
    let name, email, phone = null
    if(partner.company.company_members?.length > 0){
      name = `${partner.company.company_members[0].user.first_name} ${partner.company.company_members[0].user.last_name}`
      email = partner.company.company_members[0].user.email
      phone = `${partner.company.company_members[0].user.country_code || ''}${partner.company.company_members[0].user.phone || ''}`
    }
    return (
      <div style={{ background: '#f4f5fb' }}>
        <div style={{ margin: 20, padding: 20, background: 'white' }}>
          <h5>Partner Details</h5>
          <p class="mb-0">{name}</p>
          <p class="mb-0">{email}</p>
          <p class="mb-0">{phone}</p>
          <br/>
        </div>
      </div>
    )
  }

  render() {
    let {
      filters,
      loading,
      endpoint,
      mode,
      selectedWebsite,
      selectedPartner,
      showQuickEditModal,
      showCompanyMemberEditModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          title={ this.props.title }
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showPagination={this.props.showPagination}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
          exportButtonsEnabled={false}
          searchFilterName="sector_id"
          icon={ICON}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: column.Header === "Actions" ? 0 : 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
          SubComponent={row => this._renderDetail(row.original)}
        />

        <EditWebsiteModal
          show={showQuickEditModal}
          onCancel={() => this.setState({ showQuickEditModal: false })}
          website={selectedWebsite}
          partner={selectedPartner}
          onUpdated={website => {
            this.setState({ selectedWebsite: null, selectedPartner: null, showQuickEditModal: false })
          }}
        />

        <CompanyMemberEditModal
          show={showCompanyMemberEditModal}
          companyMember={selectedPartner?.company.company_members[0]}
          onHide={() => {
            this.setState({ selectedWebsite: null, selectedPartner: null, showCompanyMemberEditModal: false })
          }}
          onUpdated={website => {
            this.setState({ selectedWebsite: null, selectedPartner: null, showCompanyMemberEditModal: false })
          }}
        />
      </>
    )
  }
}

export default withRouter(PartnersTable)

PartnersTable.defaultProps = {
  title: "Partners",
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
}
