import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/requestResetPassword"
window.Api.User.ResetPassword         = window.Api.User.Base + "/resetPassword"
window.Api.User.RefreshToken          = window.Api.User.Base + "/login/refresh"

window.Api.Register = window.Api.Base + "/login"


window.Api.Websites         = window.Api.Base + "/websites"
window.Api.Companies        = window.Api.Base + "/companies"
window.Api.Staff            = window.Api.Base + "/companies/members/staff"
window.Api.Admin            = window.Api.Base + "/admin"
window.Api.Stats            = window.Api.Base + "/stats"
window.Api.Sectors          = window.Api.Base + "/sectors"
window.Api.Templates        = window.Api.Base + "/templates"
window.Api.Transfers        = window.Api.Base + "/transfers"
window.Api.Domains          = window.Api.Base + "/domains"
window.Api.Coupons          = window.Api.Base + "/coupons"
window.Api.Hashes           = window.Api.Base + "/hashes"
window.Api.Companies        = window.Api.Base + "/companies"
window.Api.Images           = window.Api.Base + "/images"
window.Api.ImagesSearch     = window.Api.Base + "/images-search"
window.Api.Partners         = window.Api.Base + "/partners"
window.Api.Packages         = window.Api.Base + "/packages"
window.Api.Contacts         = window.Api.Base + "/contacts"
window.Api.Notes            = window.Api.Base + "/notes"
window.Api.Pages            = window.Api.Base + "/pages"
window.Api.Stripe           = window.Api.Base + '/connect/partner'
window.Api.Files            = window.Api.Base + "/files"
window.Api.Conversations    = window.Api.Base + "/conversations"
window.Api.Messages         = window.Api.Base + "/messages"

window.Api.DNSRecords   = window.Api.Base + "/dnsrecords"

window.Api.Branding     = window.Api.Base + "/branding"

window.Api.Forms            = window.Api.Base + "/forms"
window.Api.CRM              = window.Api.Base + "/crm"
window.Api.Submissions      = window.Api.Base + "/submissions"
window.Api.SubmissionStatus = window.Api.Base + "/submission-statuses"

window.Api.Leads            = window.Api.Base + "/leads"

window.Api.StripePubishableKey = env.REACT_APP_STRIPE_PUBLISHABLE_KEY
