import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import WebsitesTable from './components/tables/WebsitesTable'

import PartnersTable from './components/tables/PartnersTable'


export default class Partners extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingStats: true,
      stats: []
    }
  }

  componentDidMount(){
    Backend.getStats()
        .then(stats => {
          let partnerStats = stats.partners
          let data = [
            {
              title: partnerStats.no,
              subtitle: "Partners",
              color: "#333333",
              type: "brand"
            },
            {
              title: partnerStats.live_websites,
              subtitle: "Live Partner Websites",
              color: "#333333",
              type: "danger"
            },
            {
              title: "€"+(partnerStats.monthly_license / 100.0),
              subtitle: "Total Monthly Licenses",
              color: "#333333",
              type: "success",
            },
          ]


          this.setState({
            stats: data,
            loadingStats: false,
          })

          setTimeout(() => {
            this.setState({
              loadingGraphs: false
            })
          }, 300)
        })
        .catch(error => {
          console.log("ddd", error)
        })
  }

  _renderStats(){
    let { stats } = this.state
    return stats.map((stat, index) => {
      return (
          <div className="col-lg-4">
            <div className={`kt-portlet kt-portlet--border-bottom-dark`}>
              <div className="kt-portlet__body kt-portlet__body--fluid">
                <div className="kt-widget26">
                  <div className="kt-widget26__content">
                    <span className="kt-widget26__number">{ stat.title }</span>
                    <span className="kt-widget26__desc">{ stat.subtitle }</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )
    })
  }

  render() {
    return (
        <>
          <div className="row">
            { this._renderStats() }
          </div>
          <div className="row">
            <PartnersTable />
          </div>
        </>
    )
  }
}
