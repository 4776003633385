import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import CouponsTable from './components/tables/CouponsTable'

export default class Live extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    return (
      <div className="row">
        <CouponsTable
          title="Coupons"
        />
      </div>
    )
  }
}
