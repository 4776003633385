import React from "react"

import moment from 'moment'

import General from '../../utils/General'

export default class Note extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      note: props.note,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      note,
    } = this.state


    return (
      <div className="kt-notes__item">
        <div className="kt-notes__media">
          <h3 className="kt-notes__user kt-font-brand kt-font-boldest">
            { General.getInitials(note.created_by.first_name+" "+note.created_by.last_name) }
          </h3>
        </div>
        <div className="kt-notes__content">
          <div className="kt-notes__section">
            <div className="kt-notes__info">
              <span className="kt-notes__desc">
                { moment(note.created_at).format("DD MMM YYYY")}
              </span>
            </div>
            {/* Starts, dropdown menu */}
            <div className="kt-notes__dropdown">
              <a href="#" className="btn btn-sm btn-icon-md btn-icon" data-toggle="dropdown">
                <i className="la la-ellipsis-h"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right py-1">
                <a href="javascript:void(0)" className="dropdown-item" onClick={() => this.props.onDeletePressed()}>Delete Note</a>
              </div>
            </div>
            {/* end, dropdown menu */}
          </div>
          <span className="kt-notes__body">
            { note.text }
          </span>
        </div>
      </div>
    )
  }
}
