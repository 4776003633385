import React from 'react'
import Modal from 'react-bootstrap/Modal'

import AsyncSelect from '../common/AsyncSelect'

import Div from '../layouts/Div'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"


export default class TemplateModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      data: props.template,
      website: props.website,
      createMode: props.template == null
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    if(!this.state.data){
      this._setup()
    }
  }

  _setup(){
    let { website } = this.state
    this.setState({ loading: true })
    Backend.getHomepage(website)
    .then(page => {
      this.setState({
        data: {
          page
        },
        loading: false
      })
      console.log("aaa", page)
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
      this.props.onCancel()
    })
  }

  _handleChange(e) {
    let { data } = this.state
    data[e.target.name] = e.target.value
    this.setState({ data })
  }

  _isFormValid(){
    let {
      data
    } = this.state

    let {
      name,
      page
    } = data

    let error = null
    if(!name){
      error = "Please enter a valid name"
    }
    else if(!page){
      error = "Please select a website"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _getOptions(pages){
    return pages.map(page => ({
      value: page.id,
      label: page.website.name,
      data: page
    }))
  }

  _onTemplateClicked(){
    let {
      data,
      website,
      createMode
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    this._handleBackendCall(data)
    .then(template => {
      this.props.onCreated(template)
      this.setState({
        loading: false
      })
      let message = createMode ? "Template successfully created!" : "Template successfully updated!"
      Notify.success(message)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _handleBackendCall(data){
    if(data.id){
      return Backend.updateTemplate(data)
    }
    data.public = AuthManager.isPlatformAdmin()
    return Backend.createTemplate(data)
  }

  render() {
    let {
      data,
      show,
      loading,
      createMode
    } = this.state

    if(!data){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Template Site</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Div
            disabled={loading}
            spinner={true}
          >
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={ data.name }
                onChange={e => this._handleChange(e)}
              />
            </div>

            {/*
            <div className="form-group">
              <label>Website</label>
              <AsyncSelect
                endpoint={window.Api.Pages}
                params={{
                  partner_id: AuthManager.currentUser?.company.id,
                  is_homepage: true
                }}
                value={ this._getOptions([data.page]) }
                onSelected={page => {
                  data.page = page
                  this.setState({ data })
                }}
                getOptions={pages => this._getOptions(pages)}
              />
            </div>
            */}

          </Div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._onTemplateClicked()}
          >
            { createMode ? "Create" : "Update" }
          </button>

        </Modal.Footer>

      </Modal>
    )
  }
}

TemplateModal.defaultProps = {
    loading: false,
}
