export default class Currency {

  static toCurrency(symbol, amount){
    if(!amount) return `${symbol ? symbol : ''}0.00`
    amount = amount/100
    amount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    amount =  `${symbol ? symbol : ''}${amount ? amount : ''}`
    return amount.replace(".00", "")
  }

  static format(value, currencyCode="eur") {
    let dollarValue = value / 100
    let symbol = Currency.getSymbol(currencyCode)
    let price = symbol + dollarValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return price.replace(".00", "")
  }

  static getSymbol(currencyCode){
    switch (currencyCode.toLowerCase()) {
      case 'eur': return '€'
      case 'gbp': return '£'
      case 'usd':
      case 'cad': return '$'
      default:    return ''
    }
  }

}
