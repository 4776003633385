import React from 'react'

import Backend from '../utils/Backend'
import General from '../utils/General'
import Notify from '../utils/Notify'

export default class StripeRedirect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this._connectStripeAccount()
  }

  _connectStripeAccount() {
    const pathname = window.location.pathname
    let token = General.getUrlParameter('state')
    let code = General.getUrlParameter('code')
    let data = {
      code,
      token
    }
    Backend.connectStripeAccount(data)
    .then(res => {
      Notify.success('Success, we connected your Stripe Account.')
      // force a reload to get updated company
      window.location =  '/settings'
    })
    .catch(error => {
      this.props.history.push('/settings')
      Notify.error(error)
    })
  }

  render() {
    return (
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid"
        id="kt_content"
      >
        <div className="row">
          <div className="col-xl-12">
            {/*begin:: Widgets/Current Orders*/}
            <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">Connecting Stripe Account</h3>
                </div>
              </div>
              <div className="kt-portlet__body">
                <form
                  className="kt-form kt-form--label-right"
                  id="kt_apps_user_add_user_form"
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <label>
                        Please wait we connect to your Stripe Account...
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/*end:: Widgets/Current Orders*/}
          </div>
        </div>
      </div>
    )
  }
}
