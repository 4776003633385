import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Select from '../common/Select'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"
import Currency from "../../../utils/Currency"

const ENABLED_OPTIONS = [
  {
    label: "Yes, This Add On Is Included In The Package",
    value: true
  },
  {
    label: "No, This Add On Is Not Included In The Package",
    value: false
  }
]

const TOGGLEABLE_OPTIONS = [
  {
    label: "Yes, I Want To Charge Customers Extra For This Add On",
    value: true
  },
  {
    label: "No, I Don't Want Customers To Be Able To Buy This Add On",
    value: false
  }
]

export default class AddOnModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      addOn: { ...props.addOn },
      loading: false,
      currency: props.currency
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e) {
    let addOn = this.state.addOn
    addOn[e.target.name] = e.target.value
    this.setState({ addOn })
  }

  _isFormValid(){
    let { addOn } = this.state

    let error = null
    if(addOn.toggleable && addOn.price == 0){
      error = "The Add On must have a price if it chargeable"
    }
    else if(addOn.price < 0){
      error = "The Add On price must be Free or greater than 0"
    }

    if(error){
      Notify.error(error)
    }

    return error == null
  }

  _onUpdated(){
    let { addOn } = this.state

    if(!this._isFormValid()){
      return
    }

    this.props.onUpdated(addOn)
  }

  render() {
    let {
      show,
      loading,
      addOn,
      currency
    } = this.state

    if(addOn == null){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Edit ${addOn.title} Add On`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Included In Package</label>
            <div className="col-lg-9 my-auto">
              <Select
                value={ENABLED_OPTIONS.find(option => option.value === addOn.enabled)}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                name="status"
                placeholder="Please Select An Option"
                onChange={option => {
                  addOn.enabled = option.value == true
                  if(addOn.enabled){
                    addOn.price = 0
                  }
                  this.setState({ addOn })
                }}
                options={ENABLED_OPTIONS}
              />
              <span>If included the price of this Add On is considered to be in the Base Package Price</span>
            </div>
          </div>

        { !addOn.enabled &&
          <>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Chargeable</label>
              <div className="col-lg-9 my-auto">
                <Select
                  value={TOGGLEABLE_OPTIONS.find(option => option.value === addOn.toggleable)}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  name="status"
                  placeholder="Please Select An Option"
                  onChange={option => {
                    addOn.toggleable = option.value == true
                    this.setState({ addOn })
                  }}
                  options={TOGGLEABLE_OPTIONS}
                />
                <span>Sets whether a customer can enable or disable this add-on, i.e. enabling or disabling the add on will affect the overall price of the package</span>
              </div>
            </div>

            { addOn.toggleable &&
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Price</label>
                  <div className="col-lg-9 my-auto">
                    <div className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">{ Currency.getSymbol(currency || "")}</span>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Free"
                        defaultValue={addOn.price ? (addOn.price / 100) : ""}
                        onChange={e => {
                          addOn.price = Math.round(parseFloat(e.target.value * 100))
                          this.setState({ addOn })
                        }}
                      />
                    </div>
                  </div>
                </div>
            }
          </>
        }

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._onUpdated(addOn)}
          >
            Save
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
