import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend"
import Switch from "react-switch";
import SmartList from "../common/SmartList";
import TextField from "@material-ui/core/TextField";
import ColorPicker from "../ColorPicker";

export default class ListSettingsModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      loading: false,
      error: null,
      list: props.list,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _isFormValid(){
    let {
      name,
      color
    } = this.state.list

    let error = null
    if(name == null || name.length === 0){
      error = "Please enter a valid form name"
    }

    if(error){
      this.setState({ error })
      return false
    }
    return true
  }

  _handleUpdateList(){
    if(!this._isFormValid()){
      return
    }
    this._updateList()
  }

  _updateList(){
    let {
      list
    } = this.state

    this.props.updateListSettings(list)
  }

  render() {
    let {
      show,
      loading,
      list,
      error,
    } = this.state

    if(!show) return null

    return (
      <Modal
        show={show}
        backdrop={"static"}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Form Settings</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { error &&
            <div className="form-group row">
              <span className="error-message">{ error }</span>
            </div>
          }

          <div className="form-group row">
            <div className="col-lg-12 my-auto">
              <TextField
                label={"List Name"}
                fullWidth
                variant={"outlined"}
                value={ list.name }
                onChange={event => {
                  list.name = event.target.value
                  this.setState({ list })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12 my-auto">
              <ColorPicker
                title={"List Colour"}
                color={ list.color }
                onColorSelected={color => {
                  list.color = color
                  this.setState({ list })
                }}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._handleUpdateList()}
          >
            Update
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
