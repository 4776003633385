import React from "react"

import moment from 'moment'

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Website from '../../../utils/Website'
import Currency from '../../../utils/Currency'

import ConfirmModal from '../../components/modal/ConfirmModal'
import TransferModal from '../../components/modal/TransferModal'
import CreateWebsiteModal from '../../components/modal/CreateWebsiteModal'
import EditWebsiteModal from '../../components/modal/EditWebsiteModal'
import TemplateModal from '../../components/modal/TemplateModal'

import Select from '../common/Select';

const ICON = (
  <g stroke="none" strokewidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z" fill="#000000"></path>
      <path d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
  </g>
)

const STATUSES = [
  {
    label: "All",
    value: "null"
  },
  {
    label: "Live",
    value: "live"
  },
  {
    label: "Demo",
    value: "demo"
  },
  {
    label: "Archived",
    value: "shutdown"
  }
]

export default class WebsitesTable extends React.Component {

  constructor(props){
    super(props)


    this.state = {
      endpoint: this._getEndpoint(props.mode),
      mode: props.mode,
      loading: true,
      filters: [],
      selectedFilter: null,
    }

    this.table = React.createRef()
  }

  componentDidMount(){
    //this._setup()
  }

  _getEndpoint(mode){
    let endpoint = window.Api.Websites

    if(mode === "null"){
      return endpoint
    }

    if(mode === "live"){
      endpoint += "?demo=false"
    }
    else if(mode === "demo"){
      endpoint += "?demo=true"
    }
    else if(mode === "shutdown"){
      endpoint += "?status=shutdown"
    }
    else if(mode === "template"){
      endpoint += "?is_template=true"
    }
    else if(mode === "partner"){
      endpoint += "?partner_site=true"
    }

    return endpoint
  }

  _onEditWebsiteClicked(website){

    this.setState({ loadingHash: true })
    let url = Website.getSiteUrl(website) + "?edit=true"
    let editWebsiteTab = window.open();
    Backend.createLoginHash(website)
    .then(hash => {
      editWebsiteTab.location.href = url+"&h="+hash.value
      this.setState({ loadingHash: false })
    })
    .catch(error => {
      editWebsiteTab.location.href = url
      this.setState({ loadingHash: false })
    })
  }

  _onQuickEditWebsiteClicked(website){
    this.setState({
      selectedWebsite: website,
      showQuickEditModal: true
    })
  }

  _setup(){
    Backend.getSectors()
    .then(sectors => {
      let filters = sectors.map(sector => ({
        label: sector.name,
        value: sector.id
      }))

      filters = [
        {
          label: "All",
          value: null
        },
        ...filters
      ]
      console.log("pp", filters)
      this.setState({
        filters,
        selectedFilter: filters[0]
      })
    })
  }

  _toggleStatus(website){
    if(website.status === "live"){
      website.status = "draft"
      return Backend.unpublish(website)
    }
    else{
      website.status = "live"
      return Backend.publish(website)
    }
  }

  _archive(website){
    website.status = "shutdown"
    return Backend.archive(website)
  }

  _getColumns() {
    let columns = [
      {
        Header: 'Created',
        id: 'created_at',
        accessor: website => moment(website.created_at).format('DD MMM YYYY')
      },
      {
        Header: 'Site Name',
        accessor: 'name',
        Cell: rowInfo => {
          let website = rowInfo.original
          let name = website.name
          if(website.company.partner){
            name += " (internal)"
          }
          return name
        },
      },
      {
        Header: 'Status',
        id: 'status',
        show: false,
        Cell: rowInfo => {
          let website = rowInfo.original
          let label = "Live"
          let type = "success"

          if(website.status === "draft"){
            label = "Draft"
            type = "warning"
          }

          if(website.status === "shutdown"){
            label = "Archived"
            type = "danger"
          }

          return (
            <span
              className={`kt-badge kt-badge--${type} kt-badge--inline kt-badge--pill`}
            >
                { label }
            </span>
          )
        }
      },
    ]

    if(!AuthManager.isPartner()){
      columns.push({
        Header: 'Partner',
        id: 'company.associate.company.name',
        Cell: rowInfo => {
          let website = rowInfo.original
          if(website.company.associate){
            return website.company.associate.company.name
          }
          if(website.company.partner){
            return website.company.name
          }
          return "-"
        },
      })
    }

    columns = [
      ...columns,
      {
        Header: '€',
        id: 'money',
        Cell: rowInfo => {
          let website = rowInfo.original
          if(website.recurring){
            return Currency.toCurrency("", website.recurring) + " (R)"
          }
          else if(website.once_off){
            return Currency.toCurrency("", website.once_off) + " (O)"
          }
          return "-"
        },
        sortable: false,
      },
      {
        Header: 'Views',
        id: 'views_no',
        width: 70,
        accessor: "views_no",
      },
      {
        Header: 'Last Edit',
        id: 'last_edited_at',
        Cell: rowInfo => {
          let website = rowInfo.original
          return website.last_edited_at ? moment(website.last_edited_at).format('DD MMM YYYY') : "-"
        }
      },
      {
        Header: 'Edited',
        id: 'last_edited_at',
        width: 70,
        Cell: rowInfo => {
          let website = rowInfo.original
          let label = "Yes"

          if(!website.last_edited_at){
            label = "No"
          }

          return label
        }
      },
      {
        Header: 'Actions',
        id: 'status',
        width: 295,
        Cell: rowInfo => {
          let website = rowInfo.original
          let { loadingHash } = this.state
          return (
            <span>
                { website.status === "live" &&
                  <a
                    href={ Website.getNativeDomain(website) }
                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    target="_blank"
                    title="View"
                  >
                    <i className="la la-eye"/>
                  </a>
                }
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                  title="Edit"
                  onClick={() => this._onEditWebsiteClicked(website)}

                >
                  <i className={`la la-${loadingHash ? "ellipsis-h" : "edit"}`}/>
                </a>
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                  onClick={() => this.setState({ selectedWebsite: website, showTransferModal: true })}
                  title="Transfer"
                >
                  <i className="la la-compass"/>
                </a>
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                  onClick={() => this.setState({ selectedWebsite: website, showCreateWebsiteModal: true })}
                  title="Duplicate"
                >
                  <i className="la la-copy"/>
                </a>
                { website.status != "shutdown" &&
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                    onClick={() => {
                      this._toggleStatus(website)
                      .then(() => {
                        this.table.current.refresh()
                      })
                    }}
                    title={ website.status === "draft" ? "Publish" : "Unpublish" }
                  >
                    <i className="la la-power-off"/>
                  </a>
                }
                { website.status != "shutdown" &&
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                    onClick={() => {
                      this._archive(website)
                      .then(() => {
                        this.table.current.refresh()
                      })
                    }}
                    title="Archive"
                  >
                    <i className="la la-trash"/>
                  </a>
                }
                { website.status == "shutdown" &&
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                    onClick={() => {
                      this._toggleStatus(website)
                      .then(() => {
                        this.table.current.refresh()
                      })
                    }}
                    title="Publish"
                  >
                    <i className="la la-power-off"/>
                  </a>
                }
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                  title="Quick Edit"
                  onClick={() => this._onQuickEditWebsiteClicked(website)}

                >
                  <i className={`la la-cog`}/>
                </a>
                { (this.state.mode != "template" && (AuthManager.isPartner() || AuthManager.isPlatformAdmin()) ) &&
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                    title="Create Template"
                    onClick={() => this.setState({ selectedWebsite: website, showTemplateModal: true })}
                  >
                    <i className={`la la-star-o`}/>
                  </a>
                }
            </span>
          )
        }
      },
    ]

    return columns
  }

  _renderTemplateModal(){
    let {
      selectedWebsite,
      showTemplateModal
    } = this.state

    if(!showTemplateModal){
      return null
    }

    return (
      <TemplateModal
        show={showTemplateModal}
        onCancel={() => this.setState({ showTemplateModal: false })}
        website={selectedWebsite}
        onCreated={template => {
          this.setState({ selectedTemplate: null, showTemplateModal: false })
        }}
      />
    )
  }

  render() {
    let {
      filters,
      loading,
      endpoint,
      mode,
      showTransferModal,
      selectedWebsite,
      showWebsiteModal,
      showQuickEditModal,
      isWebsiteArchiving,
      showWebsiteArchiveModal,
      showCreateWebsiteModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          title={ this.props.title }
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showPagination={this.props.showPagination}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
          exportButtonsEnabled={false}
          searchFilterName="sector_id"
          icon={ICON}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: column.Header === "Actions" ? 0 : 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
        />

        <ConfirmModal
          show={showWebsiteArchiveModal}
          website={selectedWebsite}
          loading={isWebsiteArchiving}
          message="Archiving the website will hide it on the site"
          cancelButtonText="No, cancel"
          confirmButtonText="Yes, archive it"
          onCancel={() => this.setState({ selectedWebsite: null, showWebsiteArchiveModal: false})}
          onConfirm={() => this._archiveWebsite(selectedWebsite)}
        />

        <TransferModal
          show={showTransferModal}
          website={selectedWebsite}
          onCancel={() => this.setState({ selectedWebsite: null, showTransferModal: false})}
          onTransferred={() => {
            this.setState({ selectedWebsite: null, showTransferModal: false})
          }}
        />

        <CreateWebsiteModal
          show={showCreateWebsiteModal}
          targetWebsite={selectedWebsite}
          createButtonText="Duplicate"
          onCancel={() => this.setState({ selectedWebsite: null, showCreateWebsiteModal: false })}
          onCreated={website => {
            this.table.current.refresh();
            this.setState({ selectedWebsite: null, showCreateWebsiteModal: false })
          }}
        />

        <EditWebsiteModal
          show={showQuickEditModal}
          onCancel={() => this.setState({ showQuickEditModal: false })}
          website={selectedWebsite}
          onUpdated={website => {
            this.setState({ selectedWebsite: null, showQuickEditModal: false })
            this.table.current.refresh()
          }}
        />

        { this._renderTemplateModal() }

      </>
    )
  }
}

WebsitesTable.defaultProps = {
  title: "Websites",
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
}
