import React from 'react'
import Modal from 'react-bootstrap/Modal'

import ConfirmModal from "./ConfirmModal"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"

export default class CompanyMemberDeleteModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      companyMember: props.companyMember,
      loading: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _deleteCompanyMember(){
    let {
      show,
      companyMember,
      loading
    } = this.state

    this.setState({ loading: true })
    Backend.deleteCompanyMember(companyMember)
    .then(() => {
      this.props.onDeleted()
    })
    .catch(error => {
      alert(error.message)
      this.setState({ loading: false })
    })
  }

  render() {
    let {
      show,
      companyMember,
      loading
    } = this.state

    if(companyMember == null){
      return null
    }

    return (
      <ConfirmModal
        show={show}
        title={`Delete ${companyMember.user.first_name}  ${companyMember.user.last_name}` }
        message="Are you sure? This action cannot be undone"
        loading={loading}
        buttonClassName="danger"
        confirmButtonText="Delete"
        onCancel={() => this.props.onCancel()}
        onConfirm={() => this._deleteCompanyMember()}
      />
    )
  }
}
