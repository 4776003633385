import React from "react"

import moment from 'moment'

import LazyLoadingList from './common/LazyLoadingList'

import Note from './Note'

import General from '../../utils/General'
import Backend from '../../utils/Backend'
import Notify from '../../utils/Notify'

export default class Notes extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      note: {
        text: ""
      },
      loading: false,
      refreshing: false,
      submission: props.submission,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }


  _handleChange(e) {
    let { note } = this.state
    note[e.target.name] = e.target.value

    this.setState({ note })
  }

  _isFormValid() {
    let { note } = this.state

    let { text } = note

    let error = null
    if (!text || text == '') {
      error = 'Please enter valid text'
    }

    if (error) {
      Notify.error(error)
      return false
    }

    return true
  }

  _saveNote() {
    let { note, submission } = this.state
    note.submission = submission.id
    if (!this._isFormValid()) {
      return
    }

    this.setState({ loading: true })
    Backend.saveNote(note)
    .then((note) => {
      this.refs.list.refresh()
      this.setState({
        loading: false,
        note: {
          text: '',
        },
      })
    })
    .catch((error) => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _renderNotes() {
    let { submission, notes, refreshing } = this.state

    if (refreshing) {
      return null
    }

    return (
      <div className="kt-notes">
        <div className="kt-notes__items">
          <LazyLoadingList
            ref="list"
            endpoint={window.Api.Notes + '?submission_id=' + submission.id}
            renderItem={(note, index) => {
              return (
                <Note
                  note={note}
                  onDeletePressed={() => {
                    Backend.deleteNote(note)
                    .then(() => [
                      this.refs.list.removeItem(note),
                    ])
                  }}
                />
              )
            }}
          />
        </div>
      </div>
    )
  }


  render() {
    let {
      note,
      loading,
      submission,
    } = this.state

    if(!submission){
      return null
    }

    return (
      <>
        <div class="separator separator-dashed separator-border-4 mb-4 mt-4"></div>

        <div className="form-group">
          <label
            style={{ fontSize: 16, color: '#48465b', fontWeight: '500' }}
          >
            Notes
          </label>
          <textarea
            name="text"
            className="form-control form-control-solid"
            value={note ? note.text : null}
            placeholder="Add a new note here..."
            onChange={(e) => this._handleChange(e)}
          />
        </div>

        <div className="form-group text-right">
          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._saveNote()}
          >
            Add Note
          </button>
        </div>

        {this._renderNotes()}
      </>
    )
  }
}
