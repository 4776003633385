import React from 'react'

import moment from "moment";

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'
import ReportsTable from "./components/tables/ReportsTable";
import PackagesTable from "./components/tables/PackagesTable";

import Card from "./components/Card";
import Chart from "./components/chart/Chart";
import Currency from "../utils/Currency";

export default class Reports extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingStats: true,
      loadingGraphs: true,
      partner: props.partner,
      stats: []
    }
  }

  componentDidMount(){
    let { partner } = this.state
    Backend.getStats(partner)
      .then(stats => {
        let websiteStats = stats.websites
        let liveWebsitesDivisor = websiteStats.live_no || 1
        let data = [
          {
            title: websiteStats.live_no,
            subtitle: "Live Sites",
            color: "#333333",
            type: "brand"
          },
          {
            title: `${websiteStats.domain_no} (${((websiteStats.domain_no/liveWebsitesDivisor) * 100).toFixed(2)}%)`,
            subtitle: "Domain Sites",
            color: "#333333",
            type: "danger"
          },
          {
            title: `${websiteStats.paid_no} (${((websiteStats.paid_no/liveWebsitesDivisor) * 100).toFixed(2)}%)`,
            subtitle: "Paid Sites",
            color: "#333333",
            type: "success",
          },
          {
            title: `${websiteStats.edited_no} (${((websiteStats.edited_no/liveWebsitesDivisor) * 100).toFixed(2)}%)`,
            subtitle: "Edited Sites",
            color: "#333333",
            type: "success",
          },
          {
            title: `${Currency.toCurrency("€", websiteStats.recurring_amount)}`,
            subtitle: "Total Monthly Revenue",
            color: "#333333",
            type: "brand"
          },
          {
            title: `${websiteStats.recurring_amount > 0 ? Currency.toCurrency("€", websiteStats.recurring_amount/websiteStats.recurring_no) : '€0'}`,
            subtitle: "Avg Monthly Revenue",
            color: "#333333",
            type: "brand"
          },
          {
            title: `${Currency.toCurrency("€", websiteStats.once_off_amount)}`,
            subtitle: "Total One Off Revenue",
            color: "#333333",
            type: "brand"
          },
          {
            title: `${websiteStats.once_off_amount > 0 ? Currency.toCurrency("€", websiteStats.once_off_amount/websiteStats.once_off_no) : '€0'}`,
            subtitle: "Avg One Off Revenue",
            color: "#333333",
            type: "brand"
          },
        ]

        let liveSites = {
          title: "Live Sites",
          color: "#333333",
          type: "success",
          data: websiteStats.live_websites.map((stat, index) => {
            return stat.count
          }),
          labels: websiteStats.live_websites.map(stat => moment(stat.date).format("DD MMM"))
        }

        let paidSites = {
          title: "Paid Sites",
          color: "#333333",
          type: "success",
          data: websiteStats.paid_websites.map((stat, index) => {
            return stat.count
          }),
          labels: websiteStats.paid_websites.map(stat => moment(stat.date).format("DD MMM"))
        }

        let monthlyRevenue = {
          title: "Monthly Revenue",
          color: "#333333",
          type: "success",
          data: websiteStats.monthly_revenue.map((stat, index) => {
            return (stat.count/100).toFixed(2)
          }),
          labels: websiteStats.monthly_revenue.map(stat => moment(stat.date).format("MMM YY"))
        }

        this.setState({
          stats: data,
          graphs: [liveSites, paidSites, monthlyRevenue],
          loadingStats: false,
        })

        setTimeout(() => {
          this.setState({
            loadingGraphs: false
          })
        }, 300)
      })
      .catch(error => {
        console.log("ddd", error)
      })
  }

  _renderStats(){
    let { stats } = this.state

    return stats.map((stat, index) => {
      return (
        <div className="col-lg-3">
          <div className={`kt-portlet kt-portlet--border-bottom-dark`}>
            <div className="kt-portlet__body kt-portlet__body--fluid">
              <div className="kt-widget26">
                <div className="kt-widget26__content">
                  <span className="kt-widget26__number">{ stat.title }</span>
                  <span className="kt-widget26__desc">{ stat.subtitle }</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  _renderGraphs(){
    let {
      graphs,
      loadingGraphs,
    } = this.state

    if(loadingGraphs){
      return null
    }

    return graphs.map(graph => {
      return (
        <Card
          title={ graph.title }
          loading={false}
        >
          <Chart
            data={ graph.data }
            labels={ graph.labels }
            color={ graph.color }
            backgroundColor={ graph.color }
            minimal={false}
            type="bar"
          />
        </Card>
      )
    })
  }

  render() {
    let {
      partner
    } = this.state

    return (
      <>
        <div className="row">
          { this._renderStats() }
        </div>
        <div className="row">
          { partner &&
            <PackagesTable
              partner={partner}
            />
          }
          <ReportsTable
            mode="live"
            title="Latest Sites"
            partner={partner}
          />
          <ReportsTable
            mode="live"
            title="Paid Sites"
            paid={true}
            partner={partner}
          />
          <ReportsTable
            mode="live"
            title="Domain Sites"
            hasDomain={true}
            partner={partner}
          />
          <ReportsTable
            mode="live"
            title="Most Views"
            sortBy={"views_no"}
            partner={partner}
          />
          <ReportsTable
            mode="live"
            title="Top Leads"
            sortBy={"leads_no"}
            hasLeads={true}
            partner={partner}
          />
          <ReportsTable
            mode="live"
            title="Top Processing"
            sortBy={"shop.gross_sales"}
            hasSales={true}
            partner={partner}
          />
          <ReportsTable
            mode="live"
            title="Latest Edits"
            sortBy={"last_edited_at"}
            hasEdited={true}
            partner={partner}
          />
        </div>
        <div className="row">
          { this._renderGraphs() }
        </div>
      </>
    )
  }
}

Reports.defaultProps = {
  partner: null
}
