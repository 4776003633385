import React, { Component } from 'react';

import FetchHelper from '../../../utils/FetchHelper'

import InfiniteScroll from 'react-infinite-scroller';

export default class SmartList extends Component  {

  constructor(props){
    super(props);
    this.state = {
      endpoint: props.endpoint,
      items: props.items
    }
  }

  componentDidMount(){
    if(this.props.endpoint){
      this._loadItems()
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _loadItems(){
    FetchHelper.get(this.props.endpoint)
    .then(items => this.props.onLoadedItems(items))
    // .catch(error => this.props.onLoadItemsError(error))
  }

  _renderItem(items, index){
    let item = items[index]
    if(this.props.renderItem){
      return this.props.renderItem(item, index)
    }

    return (
      <input
        type="text"
        className="form-control form-control-lg"
        value={ this.props.value(item, index) }
        disabled={this.props.disabled(item, index)}
        onChange={e => this.props.onChange(e.target.value, item, index)}
      />
    )
  }

  _renderDelete(item, index){
    let { items } = this.state
    if(!this.props.canDelete){
      return null
    }

    if(this.props.renderDelete){
      return this.props.renderDelete(item, index)
    }

    return (
      <a
        href="javascript:;"
        className="btn btn-sm btn-label-danger btn-bold pull-right"
        style={{ height: 38,  }}
        onClick={() => {
          items.splice(index, 1)
          this.props.onUpdated(items)
        }}
      >
        <i
          className="la la-trash-o align-select-centers"
          style={{
            marginRight: 0,
            marginTop: 4
          }}
        />
      </a>
    )
  }

  _renderItems(){
    let { items } = this.state
    return items.map((item, index) => {
      return (
        <div className={`row ${this.props.itemMarginClassName}`}>
          { this.props.showItemName &&
            <div className="col-md-12">
              <label>{ `${this.props.itemName} #${index + 1}` }</label>
            </div>
          }
          <div className="col-md-10">
            <div className="form-group mb-0">
              { this._renderItem(items, index)}
            </div>
          </div>
          <div className="col-md-2 my-auto">
            { this._renderDelete(item, index) }
          </div>
        </div>
      )
    })
  }


  render(){
    let {
      items
    } = this.state

    return (
      <>
        <h4 className="mb-4">{ this.props.title }</h4>
        { this._renderItems() }
        <div className="row">
          <div class="col-md-12">
            <a
              dhref="javascript:;"
              class="btn btn-bold btn-sm btn-label-brand"
              onClick={() => {
                this.props.onAddItemPressed()
              }}
            >
              <i class="la la-plus"></i>{`Add ${this.props.itemName}`}
            </a>
          </div>
        </div>
      </>
    )
  }
}

SmartList.defaultProps = {
  itemName: 'Item',
  canDelete: true,
  value:() => "text",
  disabled:  () => true,
  onChange: () => null,
  onAddItemPressed: () => null,
  showItemName: false,
  itemMarginClassName: "mb-3"
}
