import React from "react"
import LinearProgress from '@material-ui/core/LinearProgress'

export default class Card extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      title: props.title,
      type: props.type,
      icon: props.icon
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      title,
      icon,
      type,
    } = this.state

    return (
      <div className={this.props.className}>
				<div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
					<div className="kt-portlet__head">
						<div className="kt-portlet__head-label">
              { icon &&
                <span class="kt-portlet__head-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
  								    { icon }
  								</svg>
                </span>
              }
							<h3 className="kt-portlet__head-title">
								{ title }
							</h3>
						</div>
            { this.props.renderToolbar() }
					</div>
          { this.props.loading &&
            <LinearProgress />
          }
					<div className="kt-portlet__body">
						<div className="tab-content">
							<div className="tab-pane active" id="kt_widget4_tab1_content">
								<div className="kt-widget4">

                  { this.props.children }

								</div>
							</div>
						</div>
					</div>
				</div>
      </div>
    )
  }
}

Card.defaultProps = {
  className: "col-xl-12",
  renderToolbar: () => null
}
