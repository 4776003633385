import React from 'react'
import { withRouter } from "react-router-dom";

import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import Alert from './Alert'

import Time from '../../../utils/Time'
import AuthManager from '../../../utils/AuthManager'

class TransferDomainAlert extends React.Component {
  constructor(props){
    super(props)

    let fallbackUrl = props.fallbackUrl ? props.fallbackUrl : '/account'
    this.state = {
      domain: AuthManager.currentWebsite.domain
    }
  }

  componentDidMount(){
    if(!this.state.domain){
      return
    }
    setInterval(() => {
      this.setState({ updated: true })
    }, 1000)
  }

  render(){
    let {
      domain
    } = this.state
    if(!domain || domain.dns || domain.type === "purchase"){
      return null
    }
    let text = `${domain.name} is not yet active. Follow our guide to set it up`


    return (
      <Alert
        text={text}
        cta="Setup Domain"
        closable={false}
        onClick={() => this.props.history.push("/domain/xyz")}
      />
    )
  }
}

export default withRouter(TransferDomainAlert);
