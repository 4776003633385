import React from "react"

import Div from '../layouts/Div'
import Backend from '../../../utils/Backend'

export default class FileUpload extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      file: props.file,
      params: props.params,
      endpoint: props.endpoint
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onFileChange(e) {
    let {
      endpoint,
      params
    } = this.state

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let file = filesArr[0]
    if(file == null){
      return
    }
    this.setState({ loading: true })
    Backend.uploadFile(file, endpoint, params)
    .then(file => {
      this.setState({
        file,
        loading: false
      })
      this.props.onUploaded(file)
    })
    .catch(error => {
      this.setState({ loading: false })
      alert(error.message)
    })

  }

  render() {

    let {
      file,
      loading
    } = this.state

    let filename = file?.name || file?.original
    let label = filename || "Choose file"
    return (
			<Div disabled={this.state.loading}>
        <div class="input-group">

          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              accept={this.props.accept}
              onChange={e => this._onFileChange(e)}
            />
            <label class="custom-file-label">{label}</label>
            <span class="icon-upload material-icons">
              cloud_upload
            </span>
          </div>

          { file &&
            <>

              <button
                type="button"
                class="btn btn-icon btn-hover-primary btn-sm h-52 view"
                onClick={() => {
                  window.open(file.url || file.original)
                }}
              >
                <span class="material-icons">visibility</span>
              </button>

              <button
                type="button"
                class="btn btn-icon btn-hover-primary btn-sm h-52 delete"
                onClick={() => {
                  this.setState({ file: null })
                  this.props.onRemoved()
                }}
              >
                <span class="material-icons">delete</span>
              </button>

            </>
          }

        </div>
			</Div>
    )
  }
}
