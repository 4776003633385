import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ReactTooltip from "react-tooltip";

import moment from 'moment'
import slug from 'limax';

import Switch from "react-switch";

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'

import LazyLoadingList from './components/common/LazyLoadingList'

import ColorPicker from './components/ColorPicker'
import Div from "./components/layouts/Div"
import Section from './components/Section'

import ExplainerAlert from "./components/alert/ExplainerAlert"

import AsyncSelect from './components/common/AsyncSelect'
import Image from './components/common/Image'
import Select from './components/common/Select';
import SmartList from './components/common/SmartList';
import SwatchColorPicker from './components/common/SwatchColorPicker'


import ConfirmModal from "./components/modal/ConfirmModal"
import ImagePickerModal from './components/modal/ImagePickerModal'
import AddOnModal from "./components/modal/AddOnModal"

import Backend from "../utils/Backend"
import AuthManager from "../utils/AuthManager"
import Website from "../utils/Website"
import Notify from "../utils/Notify"
import Currency from "../utils/Currency"


const FEATURED_IMAGE_TYPE_OPTIONS = [
  {
    label: "Image/Photo",
    value: "photo"
  },
  {
    label: "Illustration/Drawing",
    value: "illustration"
  },
]

const CURRENCY_OPTIONS = [
  {
    label: "EUR",
    value: "EUR"
  },
  {
    label: "GBP",
    value: "GBP"
  },
  {
    label: "USD",
    value: "USD"
  },
]

const SWITCHABLE_OPTIONS = [
  {
    label: "Yes, Allow Clients To Switch To Other Packages",
    value: true
  },
  {
    label: "No, Clients Cannot Switch To Another Package",
    value: false
  },
]

const REFERRER_OPTIONS = [
  {
    label: "Yes, Ask Users",
    value: true
  },
  {
    label: "No, Don't Ask Users",
    value: false
  },
]

const INTERVAL_OPTIONS = [
  {
    label: "Monthly",
    value: "month"
  },
  {
    label: "Yearly",
    value: "year"
  },
]

const PAYMENT_FLOW_OPTIONS = [
  {
    label: "On Sign Up",
    value: "sign_up"
  },
  {
    label: "After Signup",
    value: "post_sign_up"
  },
  {
    label: "Add-On",
    value: "add_on"
  },
  {
    label: "Free Trial Limit (Days)",
    value: "days_post_sign_up",
    optionsLabel: "Days #",
    subOptions: [
      {
        label: "1 Day",
        value: 1
      },
      {
        label: "3 Days",
        value: 3
      },
      {
        label: "1 Week",
        value: 7
      },
      {
        label: "2 Weeks",
        value: 14
      },
      {
        label: "4 Weeks",
        value: 28
      },
    ]
  },
  {
    label: "Free Trial Limit (Site Views)",
    value: "site_views",
    optionsLabel: "Site Views #",
    subOptions: [
      {
        label: "1 Site View",
        value: 1
      },
      {
        label: "10 Site Views",
        value: 10
      },
      {
        label: "100 Site Views",
        value: 100
      },
      {
        label: "1000 Site Views",
        value: 1000
      },
      {
        label: "5000 Site Views",
        value: 5000
      },
      {
        label: "10000 Site Views",
        value: 10000
      },
    ]
  },
  {
    label: "Free Trial Limit (Sales)",
    value: "sales",
    optionsLabel: "Sales #",
    subOptions: [
      {
        label: "1 Sale",
        value: 1
      },
      {
        label: "5 Sales",
        value: 5
      },
      {
        label: "10 Sales",
        value: 10
      },
      {
        label: "20 Sales",
        value: 20
      },
      {
        label: "50 Sales",
        value: 50
      },
      {
        label: "100 Sales",
        value: 100
      },
      {
        label: "1000 Sales",
        value: 1000
      },
    ]
  },
  {
    label: "Free Trial Limit (Form Submissions)",
    value: "submissions",
    optionsLabel: "Submissions #",
    subOptions: [
      {
        label: "1 Submission",
        value: 1
      },
      {
        label: "5 Submissions",
        value: 5
      },
      {
        label: "10 Submissions",
        value: 10
      },
      {
        label: "20 Submissions",
        value: 20
      },
      {
        label: "50 Submissions",
        value: 50
      },
      {
        label: "100 Submissions",
        value: 100
      },
      {
        label: "1000 Submissions",
        value: 1000
      },
    ]
  },
]


const DELINQUENT_PAYMENT_OPTIONS = [
  {
    label: "Show A Banner/Warning To Site Visitors",
    value: "display_warning"
  },
  {
    label: "Make The Site Unavailable To Site Visitors",
    value: "lock_site"
  },
  {
    label: "Do Nothing",
    value: "do_nothing"
  },
]

export default class Package extends React.Component {
  constructor(props){
    super(props)

    let packageId = this.props.match.params.package_id

    this.state = {
      packageId,
      darkOverlay: false
    }
  }

  componentDidMount(){
    let {
      pkg
    } = this.state

    this._load()
  }

  _getState(pkg){

    let editMode = pkg != null

    pkg = pkg || {
      currency: "EUR",
      benefits: [],
      primary_color: "#986923",
      secondary_color: "#f2c98a",
      title_color: "#333333",
      cta_text_color: "#ffffff",
      cta_background_color: "#333333",
      domains: true,
      shop: true,
      featured: true,
      payment_forms: true,
      customer_relationship_management: true,
      add_ons: DEFAULT_ADD_ONS,
      featured_image_type: "illustration",
      templates: [],
      switchable: false,
      trial_period_days: null
    }

    pkg.add_ons = pkg.add_ons || DEFAULT_ADD_ONS
    pkg.templates = pkg.templates || []
    pkg.title_color = pkg.title_color ||  "#333333"
    pkg.cta_text_color = pkg.cta_text_color ||  "#ffffff"
    pkg.cta_background_color = pkg.cta_background_color || pkg.primary_color

    return {
      pkg,
      editMode
    }
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _load(){
    let {
      packageId
    } = this.state

    if(!packageId){
      this.setState({
        ...this._getState(),
        loading: false
      })
      return
    }

    Backend.getPackage(packageId)
    .then(pkg => {
      this.setState({
        ...this._getState(pkg),
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _isFormValid(){
    let {
      pkg
    } = this.state

    let {
      name,
      slug,
      amount,
      benefits,
      currency,
      switchable,
      payment_type,
      interval
    } = pkg

    let error = null

    if(!name){
      error = "Please enter a valid name"
    }
    else if(!slug){
      error = "Please enter a valid slug"
    }
    else if(!currency){
      error = "Please select a payment currency"
    }
    else if(!payment_type){
      error = "Please select a payment type"
    }
    else if(payment_type == "recurring" && !interval){
      error = "Please select a payment frequency"
    }
    else if(amount < 0 || (amount > 0 && amount < 100)){
      error = "Please enter a validprice"
    }
    else if(switchable == null){
      error = "Please select whether this package is switchable or not"
    }
    else if(this._getShowThresholdPaymentFlow() && !pkg.payment_flow_trigger_threshold){
      error = "Please select the payment options trigger amount"
    }
    else if(!error){
      for(var i = 0; i < benefits.length; i++){
        let benefit = benefits[i]
        if(!benefit.title){
          error = `Benefit #${i + 1} cannot be blank`
          break
        }
      }
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _onSaveClicked(){
    let {
      pkg,
      editMode
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    this._handleBackend(pkg)
    .then(pkg => {
      this.setState({
        pkg,
        loading: false
      })
      let message = editMode ? "Package successfully edited!" : "Package successfully created!"
      Notify.success(message)
      this.props.history.push("/packages")
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _getShowThresholdPaymentFlow(){
    let { pkg } = this.state
    let thresholdPaymentFlows = [
      "sales",
      "submissions",
      "days_post_sign_up",
      "site_views"
    ]
    return thresholdPaymentFlows.includes(pkg.payment_flow)
  }

  _renderPricingOverviewText(){
    let { pkg } = this.state
    let basePrice = pkg.amount
    basePrice = basePrice || 0

    let overview = ""
    if (basePrice == 0){
      return null
    }
    let price = Currency.format(basePrice, pkg.currency)

    if(pkg.interval == "month"){
      price += " p/m"
    }
    else if(pkg.interval == "year"){
      price += " p/y"
    }

    let connector = "triggered"
    let cta = ""
    let threshold = pkg.payment_flow_trigger_threshold || 0
    if(pkg.payment_flow == "add_on"){
      cta = "when the site owner accesses a premium add on"
    }
    else if(pkg.payment_flow == "sign_up"){
      connector = "done"
      cta = "during signup"
    }
    else if(pkg.payment_flow == "post_sign_up"){
      cta = "after signup"
    }
    else if(pkg.payment_flow == "days_post_sign_up"){
      cta = `after ${threshold} day(s)`
    }
    else if(pkg.payment_flow == "site_views"){
      cta = `after ${threshold} view(s)`
    }
    else if(pkg.payment_flow == "sales"){
      cta = `after ${threshold} sale(s)`
    }
    else if(pkg.payment_flow == "submissions"){
      cta = `after ${threshold} submission(s)`
    }
    return (
      <p className="mt-5">
        Payment collection for <b>{price}</b> will be {connector} <b>{cta}</b>
      </p>
    )
  }

  _handleBackend(pkg){
    if(pkg.id){
      return Backend.updatePackage(pkg)
    }
    return Backend.createPackage(pkg, this.props.partner)
  }

  _renderSection(title, content, collapsed=false, collapsible=false, subtitle=null){

    return (
      <Section
        title={title}
        subtitle={subtitle}
        content={content}
        collapsed={collapsed}
        collapsible={collapsible}
      />
    )

  }

  _renderAddOns(){
    let { pkg } = this.state

    let addOnKeys = Object.keys(DEFAULT_ADD_ONS)
    let addOns = addOnKeys.map(key => {
      return pkg.add_ons[key]
    })

    return (
      <div className="row">
        { addOns.map((addOn, index) => {
          let key = addOnKeys[index]
          let addOnDetails = ADD_ON_DETAILS[key]
          let priceLavel = addOn.price === 0 ? "Free" : Currency.format(addOn.price, "eur")
          let body = ""
          if(addOn.enabled){
            body = "-"
          }
          else if(addOn.toggleable){
            body = "Extra Charge"
          }
          else{
            body = "Unavailable"
          }

          let addOnItemClassName = ""
          if(addOn.enabled){
            addOnItemClassName += " add-on-item-stroke"
          }
          if(!addOn.enabled && !addOn.toggleable){
            addOnItemClassName += " add-on-item-disabled"
          }
          return (
            <div className="col-3">
              <div className={`add-on-item ${addOnItemClassName} mb-4`}>
                { addOn.enabled &&
                  <div className="add-on-item-included">
                    Included
                  </div>
                }
                <div className="add-on-item-header">
                  <i className={`${addOnDetails.icon} la-5x mx-0`} />
                </div>
                <div className="add-on-item-body">
                  <div className="add-on-item-title">
                    {addOnDetails.title}
                  </div>
                  <div className="add-on-item-subtitle text-success">
                    {priceLavel}
                  </div>
                    <div className="add-on-item-body">
                    { body }
                    </div>
                </div>
                <div className={`add-on-item-controls`}>
                  <div className="row">
                    <a
                      className="btn btn-sm btn-light btn-icon"
                      onClick={() => {
                        this.setState({
                          showAddOnModal: true,
                          selectedAddOn: {
                            key,
                            ...addOn,
                            ...addOnDetails
                          }
                        })
                      }}
                    >
                      <i className="la la-edit"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  _renderPricing(){
    let { pkg } = this.state

    let basePrice = pkg.amount
    basePrice = basePrice || 0

    let addOnKeys = Object.keys(DEFAULT_ADD_ONS)
    let addOns = addOnKeys.map(key => {
      return pkg.add_ons[key]
    })

    let totalAddOnsPrice = addOns.reduce((total, addOn) => {
      return total + addOn.price
    }, 0)

    let totalIncludedAddOnsPrice = addOns.reduce((total, addOn) => {
      if(addOn.enabled){
        total += addOn.price
      }
      return total
    }, 0)

    let totalExcludedAddOnsPrice = addOns.reduce((total, addOn) => {
      if(!addOn.enabled && addOn.toggleable){
        total += addOn.price
      }
      return total
    }, 0)

    return (
      <>
        <ExplainerAlert
          text="As customers can configure certain Add Ons, the price of a Package is not guaranteed to be fixed. As such please review the below to confirm you are happy with your package's pricing"
        />
        { this._renderPriceDetail("Standard Price (Base Price + Included Add Ons)", Currency.format(basePrice, pkg.currency))}
        { this._renderPriceDetail("Most Expensive Price (Base Price + All Add Ons)", Currency.format(basePrice + totalExcludedAddOnsPrice, pkg.currency))}

        { this._renderPricingOverviewText() }
      </>
    )
  }

  _renderPriceDetail(label, value){
    return (
      <div className="row align-items-end mb-3">
        <div className="col-6">
          {label}:
        </div>
        <div className="col-6">
          <h4 className="mb-0">{value}</h4>
        </div>
      </div>
    )
  }

  _renderTemplates(){
    let {
      pkg
    } = this.state

    return (
      <>
        <ExplainerAlert
          text="What templates would you like your clients to see when signing up for this package? Templates quickstart the creation of sites for your clients. Selecting more than one will let your client decide which template to use, while selecting just one will default to that template."
        />
        <LazyLoadingList
          ref="list"
          endpoint={window.Api.Templates}
          renderItem={(template, index) => {
            let checked = pkg.templates.find(o => o.id == template.id) != null
            return (
              <label class="kt-checkbox kt-checkbox--brand">
      					<input
                  type="checkbox"
                  checked={checked}
                  onChange={e => {
                    if(checked){
                      let index = pkg.templates.findIndex(o => o.id == template.id)
                      pkg.templates.splice(index, 1)
                    }
                    else{
                      pkg.templates.push(template)
                    }
                    this.setState({ pkg })
                  }}
                />
                {template.name}
      					<span></span>
      				</label>
            )
          }}
        />
      </>
    )
  }

  _renderForm(){
    let {
      pkg,
      imageLoading,
      showImagePicker,
      darkOverlay
    } = this.state

    let isFeaturedImagePhotoStyle = pkg.featured_image_type=="photo"? true : false;
    let featuredImageType = "Image"
    if(!isFeaturedImagePhotoStyle){
      featuredImageType = "Illustration"
    }

    let paymentFlow = pkg.payment_flow
    let thresholdPaymentFlows = [
      "sales",
      "submissions",
      "days_post_sign_up",
      "site_views"
    ]
    let showThresholdDuration = this._getShowThresholdPaymentFlow()

    let selectedPaymentFlow = PAYMENT_FLOW_OPTIONS.find(flow => flow.value === paymentFlow)

    return (
      <>
        { this._renderSection("Package Details", (
            <>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Name</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ pkg.name }
                    onChange={e => {
                      pkg.name = e.target.value
                      this.setState({ pkg })
                    }}
                    onBlur={() => {
                      if(!pkg.slug){
                        pkg.slug = slug(pkg.name)
                        this.setState({ pkg })
                      }
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Slug</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="slug"
                    className="form-control form-control-solid"
                    value={ pkg.slug }
                    onChange={e => {
                      pkg.slug = e.target.value ? e.target.value.toLowerCase() : e.target.value
                      this.setState({ pkg })
                    }}
                    onBlur={() => {
                      pkg.slug = slug(pkg.slug)
                      this.setState({ pkg })
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Featured</label>
                <div className="col-9">
                  <span className="switch">
                    <label>
                      <input
                        type="checkbox"
                        checked={pkg.featured}
                        onChange={e => {
                          pkg.featured = e.target.checked
                          this.setState({ pkg })
                        }}
                      />
                      <span></span>
                    </label>
                  </span>
                  <p className="mb-0">Featured packages can be displayed on your site</p>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Collect Referrer</label>
                <div className="col-lg-9 my-auto">
                  <Select
                      value={REFERRER_OPTIONS.find(option => option.value === pkg.collect_referrer)}
                      className="async-select-paginate"
                      classNamePrefix="async-select-paginate"
                      onChange={option => {
                        pkg.collect_referrer = option.value
                        this.setState({ pkg })
                      }}
                      options={REFERRER_OPTIONS}
                      placeholder={"Please Select An Option"}
                  />
                  <p className="mb-0">
                    Require users to state how they found out about {window.General.Branding.Name}
                  </p>
                </div>
              </div>
            </>
          ))
        }
        { this._renderSection("Package Pricing", (
            <>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Payment Currency</label>
                <div className="col-lg-9 my-auto">
                  <Select
                      value={CURRENCY_OPTIONS.find(option => option.value === pkg.currency)}
                      className="async-select-paginate"
                      classNamePrefix="async-select-paginate"
                      onChange={option => {
                        pkg.currency = option.value
                        this.setState({ pkg })
                      }}
                      options={CURRENCY_OPTIONS}
                      placeholder={"Please Select A Currency"}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Payment Type</label>
                <div className="col-lg-9 my-auto">
                  <Select
                      value={PAYMENT_TYPE_OPTIONS.find(option => option.value === pkg.payment_type)}
                      className="async-select-paginate"
                      classNamePrefix="async-select-paginate"
                      onChange={option => {
                        pkg.payment_type = option.value
                        if(pkg.payment_type == "one_time"){
                          pkg.interval = "one_time"
                          pkg.trial_period_days = 0
                        }
                        else{
                          pkg.interval = null
                        }
                        this.setState({ pkg })
                      }}
                      options={PAYMENT_TYPE_OPTIONS}
                      placeholder={"Please Select A Payment Type"}
                  />
                </div>
              </div>

              { pkg.payment_type === "recurring" &&
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Payment Frequency</label>
                  <div className="col-lg-9 my-auto">
                    <Select
                        value={INTERVAL_OPTIONS.find(option => option.value === pkg.interval)}
                        className="async-select-paginate"
                        classNamePrefix="async-select-paginate"
                        onChange={option => {
                          pkg.interval = option.value
                          this.setState({ pkg })
                        }}
                        options={INTERVAL_OPTIONS}
                        placeholder={"Please Select A Frequency"}
                    />
                  </div>
                </div>
              }

              {(pkg.payment_type) &&
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Base Price</label>
                  <div className="col-lg-9 my-auto">
                    <input
                        type="text"
                        name="amount"
                        className="form-control form-control-solid"
                        placeholder="Free"
                        defaultValue={pkg.amount ? (pkg.amount / 100) : ""}
                        onChange={e => {
                          pkg.amount = Math.round(parseFloat(e.target.value * 100))
                          if(pkg.amount == 0){
                            pkg.trial_period_days = 0
                          }
                          this.setState({pkg})
                        }}
                    />
                  </div>
                </div>
              }

              {(pkg.amount > 0 && pkg.payment_type == "recurring") &&
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Free Trial Days</label>
                  <div className="col-lg-9 my-auto">
                    <input
                        type="number"
                        name="trial_period_days"
                        className="form-control form-control-solid"
                        placeholder=""
                        min="0"
                        defaultValue={pkg.trial_period_days || ""}
                        onChange={e => {
                          pkg.trial_period_days = parseInt(e.target.value)
                          this.setState({pkg})
                        }}
                    />
                  </div>
                </div>
              }

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Payment Options</label>
                <div className="col-lg-9 my-auto">
                  <Select
                      value={PAYMENT_FLOW_OPTIONS.find(option => option.value === pkg.payment_flow)}
                      className="async-select-paginate"
                      classNamePrefix="async-select-paginate"
                      onChange={option => {
                        pkg.payment_flow = option.value
                        pkg.payment_flow_trigger_threshold = null
                        this.setState({ pkg })
                      }}
                      options={PAYMENT_FLOW_OPTIONS}
                      placeholder={"Please Select When To Collect Payment"}
                  />
                </div>
              </div>

              { showThresholdDuration &&
                <>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">{ selectedPaymentFlow.optionsLabel }</label>
                    <div className="col-lg-9 my-auto">
                      <Select
                          key={pkg.payment_flow}
                          value={selectedPaymentFlow.subOptions?.find(option => option.value === pkg.payment_flow_trigger_threshold)}
                          className="async-select-paginate"
                          classNamePrefix="async-select-paginate"
                          onChange={option => {
                            pkg.payment_flow_trigger_threshold = option.value
                            this.setState({ pkg })
                          }}
                          options={selectedPaymentFlow.subOptions}
                          placeholder={"Please Select An Option"}
                      />
                    </div>
                  </div>
                </>
              }

              { false &&
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Switchable</label>
                  <div className="col-lg-9 my-auto">
                    <Select
                        value={SWITCHABLE_OPTIONS.find(option => option.value === pkg.switchable)}
                        className="async-select-paginate"
                        classNamePrefix="async-select-paginate"
                        onChange={option => {
                          pkg.switchable = option.value
                          this.setState({ pkg })
                        }}
                        options={SWITCHABLE_OPTIONS}
                        placeholder={"Please Select An Option"}
                    />
                    <span>If yes, clients can switch to any other 'Switchable' package</span>
                  </div>
                </div>
              }
            </>
          ))
        }
        {
          this._renderSection("Package Payment Prompt", (
            <>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Site Owner Message Title</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ pkg.payment_flow_owner_title }
                    onChange={e => {
                      pkg.payment_flow_owner_title = e.target.value || null
                      this.setState({ pkg })
                    }}
                    placeholder="Activate Your Account"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Site Owner Message Body</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ pkg.payment_flow_owner_subtitle }
                    onChange={e => {
                      pkg.payment_flow_owner_subtitle = e.target.value || null
                      this.setState({ pkg })
                    }}
                    placeholder="Your site is at risk of being deleted"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Site Owner Message CTA</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ pkg.payment_flow_owner_cta }
                    onChange={e => {
                      pkg.payment_flow_owner_cta = e.target.value || null
                      this.setState({ pkg })
                    }}
                    placeholder="Activate"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Delinquent Payment Handling</label>
                <div className="col-lg-9 my-auto">
                  <Select
                      value={DELINQUENT_PAYMENT_OPTIONS.find(option => option.value === pkg.payment_flow_delinquent_treatment)}
                      className="async-select-paginate"
                      classNamePrefix="async-select-paginate"
                      onChange={option => {
                        pkg.payment_flow_delinquent_treatment = option.value
                        this.setState({ pkg })
                      }}
                      options={DELINQUENT_PAYMENT_OPTIONS}
                      placeholder={"Please Select How To Treat Delinquent Payments"}
                  />
                </div>
              </div>

              { pkg.payment_flow_delinquent_treatment == "display_warning" &&
                <>
                  <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Visitor Warning Title</label>
                    <div className="col-lg-9 my-auto">
                      <input
                        type="text"
                        name="name"
                        className="form-control form-control-solid"
                        value={ pkg.payment_flow_visitor_title }
                        onChange={e => {
                          pkg.payment_flow_visitor_title = e.target.value || null
                          this.setState({ pkg })
                        }}
                        placeholder="Website Deactivated"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Visitor Warning Body</label>
                    <div className="col-lg-9 my-auto">
                      <input
                        type="text"
                        name="name"
                        className="form-control form-control-solid"
                        value={ pkg.payment_flow_visitor_subtitle }
                        onChange={e => {
                          pkg.payment_flow_visitor_subtitle = e.target.value || null
                          this.setState({ pkg })
                        }}
                        placeholder="This website has been deactivated due to failed payments. Please contact the site owner to resolve."
                      />
                    </div>
                  </div>
                </>
              }
            </>
          ))
        }

        { this._renderSection("Add Ons", (
            <>
              <ExplainerAlert
                text="Choose the Add Ons that are available to your customers on this package. You can make Add Ons available at no extra cost or add an additional fee to the base package price."
              />
              { this._renderAddOns()}
            </>
          ))
        }
        { this._renderSection("Package Pricing Overview", this._renderPricing()) }
        { this._renderSection("Package Benefits", (
            <>
              <ExplainerAlert
                text="What benefits does this package offer? Benefits are visible on your package's signup page and are useful in conveying your packages key offerings"
              />
              <div className="form-group row questionBox" id="questionInput" style={{border: 'none'}}>
                <div className="col-12">
                  <SmartList
                    items={pkg.benefits}
                    itemName="Benefit"
                    showItemName={true}
                    value={item => item.title}
                    disabled={item => item.id != null}
                    itemMarginClassName="mb-5"
                    onChange={(value, item, index) => {
                      pkg.benefits[index].title = value
                      this.setState({ pkg })
                    }}
                    onUpdated={benefits => {
                      pkg.benefits = benefits
                      this.setState({ pkg })
                    }}
                    onAddItemPressed={() => {
                      pkg.benefits.push({title: "", subtitle: ""})
                      this.setState({ pkg })
                    }}
                  />
                </div>
              </div>
            </>
          ), true, true)
        }
        { this._renderSection("Package Styling", (
            <>

              <ExplainerAlert
                text="Customise your package's sign up page using the below."
              />

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Title Pitch</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ pkg.title }
                    placeholder="Create A Website In 30 Seconds..."
                    onChange={e => {
                      pkg.title = e.target.value
                      this.setState({ pkg })
                    }}
                  />
                  <p>The pitch text of the package</p>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Sub Line</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ pkg.subline }
                    placeholder="Build Your Own Site"
                    onChange={e => {
                      pkg.subline = e.target.value
                      this.setState({ pkg })
                    }}
                  />
                  <p>The text under the pitch text of the package</p>
                </div>
              </div>

              {/*
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Form Title</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ pkg.subtitle }
                    placeholder="Create Your Site"
                    onChange={e => {
                      pkg.subtitle = e.target.value
                      this.setState({ pkg })
                    }}
                  />
                  <p>The title of the signup form your user sees</p>
                </div>
              </div>
              */}

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Submit Button Text</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ pkg.submit_cta }
                    placeholder="Create My Site"
                    onChange={e => {
                      pkg.submit_cta = e.target.value
                      this.setState({ pkg })
                    }}
                  />
                  <p>The submit button title your user clicks</p>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Featured Image Style</label>
                <div className="col-lg-9 my-auto">
                  <Select
                    value={FEATURED_IMAGE_TYPE_OPTIONS.find(option => option.value === pkg.featured_image_type)}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    name="status"
                    placeholder="Please Select An Option"
                    onChange={option => {
                      pkg.featured_image_type = option.value
                      pkg.featured_image = null
                      this.setState({ pkg })
                    }}
                    options={FEATURED_IMAGE_TYPE_OPTIONS}
                  />
                  <p></p>
                </div>
              </div>


              <div className='form-group row'>

                <div className='col-lg-3'>
                  { pkg.featured_image_type &&
                    <>
                      <div class="form-group fv-plugins-icon-container">
                        <label>{featuredImageType}</label>
                        <div
                          className="form-control form-control-solid text-center p-0 d-flex justify-content-center align-items-center cursor-pointer choose-image"
                          onClick={() => this.setState({ showImagePicker: true })}
                        >
                          { !pkg.featured_image &&
                            <>
                              <div className="choose-image-overlay"/>
                              <div className="bg-text p-2">
                                <p className="my-auto">Select {featuredImageType}</p>
                              </div>
                            </>
                          }
                          { (pkg.featured_image || imageLoading) &&
                            <div className="w-100 shine shine-overlay">
                              { pkg.featured_image &&
                                <>
                                  { !isFeaturedImagePhotoStyle &&
                                    <div className="w-100 uploaded-image type-illustration" style={{ backgroundColor: 'white' }}>
                                      <Image
                                        className=""
                                        src={pkg.featured_image.original}
                                        type={pkg.featured_image_type}
                                        primaryColor={pkg.primary_color}
                                        onClick={() => this.setState({ showImagePicker: true })}
                                      />
                                    </div>
                                  }
                                  { isFeaturedImagePhotoStyle &&
                                    <div className="uploaded-image" style={{ backgroundImage: "url("+pkg.featured_image.original+")" }} ></div>
                                  }
                                </>
                              }
                            </div>
                          }
                        </div>
                        <p>&nbsp;</p>
                      </div>
                      <ImagePickerModal
                        show={showImagePicker}
                        type={pkg.featured_image_type}
                        primaryColor={pkg.primary_color}
                        onSelected={image =>  {
                          this.setState({ imageLoading: true, showImagePicker: false })
                          Backend.addImage(image)
                          .then(image => {
                            pkg.featured_image = image
                            this.setState({ pkg, imageLoading: false })
                          })
                          .catch(error => {
                            Notify.error(error.message)
                            this.setState({ imageLoading: false })
                          })
                        }}
                        onHide={() => this.setState({ showImagePicker: false })}
                      />
                    </>
                  }
                </div>

                <div className='col-lg-9'>

                  <div className='row'>


                    {/* Begin : Left column */}
                    <div className='col-6'>

                      {/* Begin : Left Background Colour */}
                      <div className="form-group">
                        <label>Left Background Colour</label>
                        <div>
                          <SwatchColorPicker
                            color={ pkg.secondary_color }
                            onColorChange={(color) => {
                              pkg.secondary_color = color.hex
                              this.setState({ pkg })
                            }}
                          />
                        </div>
                      </div>
                      {/* End : Left Background Colour */}

                      {/* Begin : Left Text Colour */}
                      <div className="form-group">
                        <label>Left Text Colour</label>
                        <div>
                          <SwatchColorPicker
                            color={ pkg.primary_color }
                            onColorChange={(color) => {
                              pkg.primary_color = color.hex
                              this.setState({ pkg })
                            }}
                          />
                        </div>
                      </div>
                      {/* End : Left Text Colour */}

                      {/* Begin : Left Dark Overlay */}
                      <div className="form-group">
                        <label>Left Dark Overlay</label>
                        <div>
                          <span className="switch">
                            <label className='mb-0'>
                              <input
                                type="checkbox"
                                checked={pkg.overlay}
                                onChange={e => {
                                  pkg.overlay = !pkg.overlay
                                  this.setState({pkg});
                                }}
                              />
                              <span></span>
                            </label>
                          </span>
                        </div>
                      </div>
                      {/* End : Left Dark Overlay */}

                    </div>
                    {/* End : Left column */}

                    {/* Begin : Right column */}
                    <div className='col-6'>

                      {/* Begin : Right Title Colour */}
                      <div className="form-group">
                        <label>Right Title Colour</label>
                        <div>
                          <SwatchColorPicker
                            color={ pkg.title_color }
                            onColorChange={(color) => {
                              pkg.title_color = color.hex
                              this.setState({ pkg })
                            }}
                          />
                        </div>
                      </div>
                      {/* End : Right Title olour */}

                      {/* Begin : Right Btn Text Colour */}
                      <div className="form-group">
                        <label>Right Button Text Colour</label>
                        <div>
                          <SwatchColorPicker
                            color={ pkg.cta_text_color }
                            onColorChange={(color) => {
                              pkg.cta_text_color = color.hex
                              this.setState({ pkg })
                            }}
                          />
                        </div>
                      </div>
                      {/* End : Right Btn Text Colour */}

                      {/* Begin : Right Btn BG Colour */}
                      <div className="form-group">
                        <label>Right Button Background Colour</label>
                        <div>
                          <SwatchColorPicker
                            color={ pkg.cta_background_color }
                            onColorChange={(color) => {
                              pkg.cta_background_color = color.hex
                              this.setState({ pkg })
                            }}
                          />
                        </div>
                      </div>
                      {/* End : Right Btn Text Colour */}

                    </div>
                    {/* End : Right column */}

                  </div>

                </div>

              </div>

              {/* Starts : separator */}
              <div class="c-separator form-group row align-items-center text-center">
                <div class="col-3">
                  <hr/>
                </div>
                <div class="col-6">
                  <span>
                    <strong>
                      Package Signup Page Preview
                    </strong>
                  </span>
                </div>
                <div class="col-3">
                  <hr/>
                </div>
              </div>
              {/* end : separator */}

              {/* Starts : Preview */}
              <div
                className={`row preview style ${isFeaturedImagePhotoStyle? "photo" : "illustration"}`}
              >

                <div
                  className={`details dark-overlay ${pkg.overlay}`}
                  style={{
                    backgroundColor: pkg.secondary_color,
                    backgroundImage: "url("+ `${pkg.featured_image && isFeaturedImagePhotoStyle ? pkg.featured_image.original:"" }` +")",
                  }}
                >

                  <div className='s-one'>
                    <img className='logo' alt="Logo" src={window.General.Branding.Logo} />
                    <h3>{ pkg.title || "Make Any Site In 3 Minutes" }</h3>
                    <h4>{pkg.subline || ""}</h4>
                    <ul>
                      { pkg.benefits.map(benefit => {
                          return (
                            <li>
                              <i class="symbol flaticon2-check-mark"></i>
                              { benefit.title }
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>

                  <div className='s-two'>
                    { pkg.featured_image &&
                      <>
                        { !isFeaturedImagePhotoStyle &&
                          <div className="illustration">
                            <Image
                              className=""
                              src={pkg.featured_image.original}
                              alt=""
                              type="illustration"
                              primaryColor={pkg.primary_color}
                            />
                          </div>
                        }
                      </>
                    }
                  </div>

                  {pkg.primary_color &&
                    <style dangerouslySetInnerHTML={{__html: `
                      .page.package .preview .details h3,
                      .page.package .preview .details h4,
                      .page.package .preview .details li {
                          color: ${pkg.primary_color};
                      }
                      .page.package .preview .form .btn.btn-primary {
                        background-color: ${pkg.cta_background_color};
                        border-color: ${pkg.cta_background_color};
                        color: ${pkg.cta_text_color};
                      }
                      .page.package .preview .form h3 {
                        color: ${pkg.title_color};
                      }
                    `}}/>
                  }

                </div>

                <div className='form'>

                  <h3
                    className=""
                  >
                      { pkg.subtitle || "Create Your Site" }
                  </h3>

                  <div className='design'>

                    { this._renderPreviewField() }
                    { this._renderPreviewField() }

                    <div className='row'>
                      <div className='col-6'>
                        { this._renderPreviewField() }
                      </div>
                      <div className='col-6'>
                        { this._renderPreviewField() }
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-6'>
                        { this._renderPreviewField() }
                      </div>
                      <div className='col-6'>
                        { this._renderPreviewField() }
                      </div>
                    </div>

                    { this._renderPreviewField() }

                  </div>

                  <button
                    type="button"
                    class="btn btn-primary no-click"
                  >
                    { pkg.submit_cta || "Create My Site" }
                  </button>

                </div>

              </div>
              {/* End : Preview */}

            </>
          ), true, true)
        }
      </>
    )
  }

  _renderPreviewField(){
    return(
      <>
        <div className="field">
          <div className='text'></div>
          <div className='input'></div>
        </div>
      </>
    )
  }

  render() {
    let {
      pkg,
      error,
      loading,
      editMode,
      selectedAddOn,
      showAddOnModal,
    } = this.state

    if(!pkg){
      return null
    }

    let title = editMode ? "Update Package" : "Add Package"
    let saveButtonTitle = title

    return (
      <div className='page package'>
        <Div
          className="col-lg-8"
          disabled={loading}
        >

          { this._renderForm() }


          <div className="kt-portlet__foot">
            <div className="kt-form__actions pull-right">

              <button
                type="button"
                className="btn btn-primary ml-3"
                disabled={loading}
                onClick={() => this._onSaveClicked()}
              >
                { saveButtonTitle }
              </button>
            </div>
          </div>
        </Div>
        <ReactTooltip effect="solid"/>
        <AddOnModal
          show={showAddOnModal}
          addOn={selectedAddOn}
          currency={pkg.currency}
          onHide={() => this.setState({ showAddOnModal: false, selectedAddOn: null })}
          onUpdated={addOn => {
            pkg.add_ons[addOn.key] = addOn
            this.setState({ pkg, showAddOnModal: false, selectedAddOn: null })
          }}
        />
      </div>
    )
  }
}

const PAYMENT_TYPE_OPTIONS = [
  {
    label: "Once Off",
    value: "one_time"
  },
  {
    label: "Recurring",
    value: "recurring"
  }
]

const DEFAULT_ADD_ON = {
  price: 0,
  enabled: true,
  toggleable: false,
}

const DEFAULT_ADD_ONS = {
  shop: DEFAULT_ADD_ON,
  domains: DEFAULT_ADD_ON,
  crm: DEFAULT_ADD_ON,
  blog: DEFAULT_ADD_ON,
  mobile_app: DEFAULT_ADD_ON,
  chat: DEFAULT_ADD_ON,
  visitors: DEFAULT_ADD_ON,
  files: DEFAULT_ADD_ON,
  team: DEFAULT_ADD_ON,
  web_designer: DEFAULT_ADD_ON,
  payment_widget: DEFAULT_ADD_ON,
  bookings: DEFAULT_ADD_ON,
}

const ADD_ON_DETAILS = {
  shop: {
    icon: "la la-shopping-cart",
    title: "eCommerce Shop",
    description: "Spin up an eCommerce Shop and start selling your products and services to your customers. Control shipping and variables products with ease."
  },
  payment_widget: {
    icon: "la la-credit-card",
    title: "Payment Widget",
    description: "Take payment from your customers directly on your website, with ease. Direct your customers to your website to pay for money owed or for deposits upfront."
  },
  bookings: {
    icon: "la la-calendar",
    title: "Booking System",
    description: "Acceppt and manage bookings directly on your website. Bookings lets you easily organise and manage all your bookings across multiple services/resources."
  },
  domains: {
    icon: "la la-globe",
    title: "Custom Domain",
    description: "Personalise your site with a custom domain. A custom domain helps strengthens your brand, gain customer trust and improve ranking on Google."
  },
  crm: {
    icon: "la la-group",
    title: "CRM & Contact Form",
    description: "A simple, yet powerful built in CRM system. Customise your contact form fields, and manage your leads and customers easily"
  },
  blog: {
    icon: "la la-newspaper-o",
    title: "News & Blog",
    description: "Engage with your customers, and keep your website up to date with a blog. Making a blog helps attract new visitors by improving your ranking on Google"
  },
  mobile_app: {
    icon: "la la-mobile-phone",
    title: "Mobile App",
    description: "Manage your website on the go. From viewing analytics, managing sales, responding to visitors and leads use the Mobile App to manage your business easily"
  },
  chat: {
    icon: "la la-comment",
    title: "Live Customer Chat",
    description: "Chat with your visitors in real time. Live Chat lets you engage with people when their most interested in your site. Convert visitors into leads and leads into sales."
  },
  visitors: {
    icon: "la la-bar-chart",
    title: "Visitors & Traffic",
    description: "Get detailed insights on your site visitors. Learn what works and what doesn't by viewing the pages and actions your visitors take on your site to help optimise your KPIs"
  },
  files: {
    icon: "la la-inbox",
    title: "Media Bank",
    description: "Distribute files and documents to your visitors easily & effortlessly. The Media Bank lets your visitors download even large files in seconds from around the globe"
  },
  team: {
    icon: "la la-group",
    title: "Team Members",
    description: "Invite your co workers to help manage your site and grow your online presence. Team Members lets you get your team onboard to help with day to day admin"
  },
  web_designer: {
    icon: "la la-paint-brush",
    title: "Web Designer",
    description: "Get access to a Personal Web Designer to help maximise your sites potential. Your Personal Web Designer is reachable by Live Chat from within your dashboard at the click of a button"
  },
}
