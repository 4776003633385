import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Div from '../layouts/Div'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Currency from "../../../utils/Currency"
import Notify from "../../../utils/Notify"
import General from "../../../utils/General"

import Select from '../common/Select';

import AsyncSelect from '../common/AsyncSelect'



export default class CouponModal extends React.Component {
  constructor(props){
    super(props)
    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      isEditMode: props.coupon != null,
      coupon: props.coupon || {
        one_time_amount: 0,
        recurring_amount: 0
      }
    }
  }

  _handleChange(e) {
    let coupon = this.state.coupon
    coupon[e.target.name] = e.target.value
    this.setState({ coupon })
  }

  _isFormValid(){
    let {
      coupon
    } = this.state

    let {
      one_time_amount,
      recurring_amount,
      name,
      code
    } = coupon

    let error = null

    if(!name || name === ""){
      error = "Please enter a valid name"
    }
    else if(one_time_amount < 0 || (one_time_amount > 0 && one_time_amount < 100)){
      error = "Please enter a valid one time amount"
    }
    else if(recurring_amount < 0 || (recurring_amount > 0 && recurring_amount < 100)){
      error = "Please enter a valid recurring amount"
    }
    else if(!code || code == ""){
      error = "Please enter a valid code"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _onCreateClicked(){
    let {
      coupon,
      isEditMode
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    this._handleBackend(coupon)
    .then(coupon => {
      this.setState({
        coupon: coupon,
        loading: false
      })
      this.props.onCreated(coupon)
      let message = isEditMode ? "updated" : "created"
      Notify.success(`Coupon successfully ${message}!`)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _handleBackend(coupon){
    if(coupon.id){
      return Backend.updateCoupon(coupon)
    }
    return Backend.createCoupon(coupon)
  }

  render() {
    let {
      show,
      loading,
      coupon,
      isEditMode
    } = this.state

    let title = isEditMode ? "Update Coupon" : "Create Coupon"
    let createButtonText = isEditMode ? "Update" : "Create"

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Div
            disabled={loading}
            spinner={false}
          >
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="name"
                  className="form-control form-control-solid"
                  value={ coupon.name }
                  onChange={e => {
                    coupon.name = e.target.value
                    this.setState({ coupon })
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Code</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="code"
                  className="form-control form-control-solid"
                  value={ coupon.code }
                  onChange={e => {
                    coupon.code = e.target.value ? e.target.value.toUpperCase() : e.target.value
                    this.setState({ coupon })
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Once Off Amount</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="code"
                  className="form-control form-control-solid"
                  value={ coupon.one_time_amount / 100 }
                  onChange={e => {
                    coupon.one_time_amount = Math.round(parseFloat(e.target.value * 100))
                    this.setState({ coupon })
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Recurring Amount</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="code"
                  className="form-control form-control-solid"
                  value={ coupon.recurring_amount / 100 }
                  onChange={e => {
                    coupon.recurring_amount = Math.round(parseFloat(e.target.value * 100))
                    this.setState({ coupon })
                  }}
                />
              </div>
            </div>
          </Div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._onCreateClicked()}
          >
            { createButtonText }
          </button>

        </Modal.Footer>

      </Modal>
    )
  }
}

CouponModal.defaultProps = {
    loading: false,
}
