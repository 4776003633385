import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Select from '../common/Select'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"


const TYPES = [
  {
    label: "A",
    value: "A"
  },
  {
    label: "CNAME",
    value: "CNAME"
  },
  {
    label: "MX",
    value: "MX"
  },
  {
    label: "TXT",
    value: "TXT"
  }
]
export default class DNSRecordModal extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      editMode: props.record != null,
      domain: props.domain,
      record: props.record || {
        type: TYPES[0].value,
        content: null,
        ttl: 1,
      }
    }
  }

  _handleUpdate(){
    let {
      record,
      domain,
      editMode,
    } = this.state

    this.setState({ loading: true })
    Backend.updateDNS(domain, record)
    .then(record => {
      this.setState({ loading: false })
      this.props.onUpdated(record)
      Notify.success("DNS Records Updated Successfully")
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _handleChange(e) {
    let record = this.state.record
    record[e.target.name] = e.target.value
    this.setState({ record })
  }

  render() {
    let {
      show,
      record,
      loading,
      editMode
    } = this.state

    let title = editMode ? "Edit DNS Record" : "Add DNS Record"


    let ContentTag = record.type !== "TXT" ? "input" : "textarea"
    let contentTitle = "IPv4 Address"
    if(record.type == "CNAME"){
      contentTitle = "Target"
    }
    if(record.type == "MX"){
      contentTitle = "Mail Server"
    }
    if(record.type == "TXT"){
      contentTitle = "Content"
    }
    return (
      <Modal
        show={show}
        onHide={() => {
          if(!loading){
            this.props.onCancel()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Type</label>
            <div className="col-lg-9 my-auto">
              <Select
                value={TYPES.find(type => type.value === record.type)}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                name="type"
                placeholder="Please Select An Option"
                onChange={option => {
                  record.type = option.value
                  if(record.type !== "MX"){
                    record.priority = null
                  }
                  this.setState({ record })
                }}
                options={TYPES}
                isDisabled={record.id != null}
              />
              </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9 my-auto">
              <input
                name="name"
                className="form-control"
                value={ record.name }
                placeholder="Use @ for root"
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">{ contentTitle }</label>
            <div className="col-lg-9 my-auto">
              <ContentTag
                name="content"
                className="form-control"
                value={ record.content }
                placeholder=""
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">TTL</label>
            <div className="col-lg-9 my-auto">
              <input
                name="ttl"
                className="form-control"
                value="Auto"
                onChange={e => this._handleChange(e)}
                disabled
              />
            </div>
          </div>

          { record.type === "MX" &&
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Priority</label>
              <div className="col-lg-9 my-auto">
                <input
                  name="priority"
                  type="number"
                  step="1"
                  className="form-control"
                  value={ record.priority }
                  onChange={e => this._handleChange(e)}
                />
                <span>0 - 65535</span>
              </div>
            </div>
          }
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            disabled={loading}
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._handleUpdate()}
          >
            { editMode ? "Update" : "Save" }
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

DNSRecordModal.defaultProps = {
    message: null,
    loading: false,
}
