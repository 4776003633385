import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

export default class Card extends React.Component {
  render() {
    let { card } = this.props
    return (
      <Draggable draggableId={card.id.toString()} index={this.props.index}>
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDragging ? 'card-dragging-container kanban-item' : 'card-container kanban-item'
            }
            style={{ cursor: 'pointer' }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            innerRef={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <div
              class="d-flex flex-column align-items-start text-primary"
              onClick={() => this.props.onClicked(card, this.props.index)}
            >
              { this.props.render(card) }
            </div>
          </div>
        )}
      </Draggable>
    )
  }
}

Card.defaultProps = {}
