import React from 'react'

import AsyncPaginate from 'react-select-async-paginate';

import FetchHelper from '../../../utils/FetchHelper'

export default class AsyncSelect extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      endpoint: props.endpoint,
      search: '',
      prevSearch: '',
      items: [],
      nextPageUrl: null,
      value: this.props.value,
    }
  }

  _loadOptions(search, prevOptions){
    let {
      endpoint,
      prevSearch,
      nextPageUrl,
      items
    } = this.state

    let url = `${endpoint}?search_term=${search}&order_by=name`
    if(this.props.params){
      for(const [key, value] of Object.entries(this.props.params)){
        url += `&${key}=${value}`
      }
    }
    let nextPage = false
    if(search === prevSearch && nextPageUrl){
      url = nextPageUrl
      nextPage = true
    }

    return FetchHelper.get(url)
    .then(response => {
      let newOptions = this.props.getOptions(response.results)
      if(nextPage){
        items = [
          ...items,
          ...response.results
        ]
      }
      else{
        items = response.results
      }

      this.setState({
        items,
        nextPageUrl: response.next,
        prevSearch: search
      })
      return {
        options: newOptions,
        hasMore: response.next != null
      }
    })
  };

  render(){
    return (
      <AsyncPaginate
        className="async-select-paginate"
        isMulti={this.props.isMulti}
        value={this.state.value}
        loadOptions={this._loadOptions.bind(this)}
        debounceTimeout={300}
        onChange={value => {

          this.setState({ value })
          if(this.props.isMulti){
            this.props.onSelected(value)
          }
          else{
            this.props.onSelected(value.data)
          }
        }}
      />
    )
  }
}

AsyncSelect.defaultProps = {
  isMulti: false
}
