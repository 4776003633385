import React from "react"

import Div from "../layouts/Div"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

export default class Stripe extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      data: null,
    }
  }

  componentDidMount(){
    this._load()
  }

  _load() {
    this.setState({ loading: true })
    Backend.getCompany(AuthManager.currentUser.company.id)
    .then(data => {
      this.setState({
        data,
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _onConnectPressed() {
    let { company } = this.state
    this.setState({ loading: true })
    Backend.getStripeConnectUrl()
    .then(res => {
      let url = res.connect_url
      window.location = `${url}&redirect_uri=https://${window.location.hostname}/stripe-connect`
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }


  render() {
    let {
      data,
      loading
    } = this.state

    if(!data){
      return null
    }

    let stripeConnected = data.payments_connected

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
        >
        <div className="kt-heading kt-heading--md">Setup Your Bank Details</div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              {stripeConnected &&
                <p> You have successfully connected your account</p>
              }
              {!stripeConnected &&
                <button
                  onClick={() => this._onConnectPressed()}
                  type="button"
                  className="btn btn-label-dark btn-bold btn-sm kt-margin-t-5 kt-margin-b-5"
                >
                  Connect to Stripe
                </button>
              }
            </div>
          </div>
        </Div>
      </>
    )
  }
}
