import React from 'react'

import AuthManager from '../utils/AuthManager'

import CRMBoard from './components/board/CRMBoard'
import Switch from "react-switch";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import SubmissionsTable from "./components/tables/SubmissionsTable";


export default class Submissions extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      tableView: false,
    }
  }

  render() {

    let {
      tableView,
    } = this.state

    return (
      <div className="text-center c-tabs">
        <Tabs
          defaultActiveKey="kanban"
          id="noanim-tab-example"
          variant="pills"
        >
          <Tab eventKey="kanban" title="List View">
            <div className="text-left">
              <CRMBoard/>
            </div>
          </Tab>
          <Tab eventKey="table" title="Table View">
            <div className="row px-15 text-left m-0">
              <SubmissionsTable/>
            </div>
          </Tab>
        </Tabs>
      </div>
    )

  }
}
