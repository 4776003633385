import React from "react"
import { withRouter } from "react-router-dom";

class QuickPanel extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }


  render() {
    let { title } = this.state

    return (
      <div id="kt_quick_panel" className="kt-quick-panel">
        <a href="#" className="kt-quick-panel__close" id="kt_quick_panel_close_btn"><i className="flaticon2-delete"></i></a>
        <div className="kt-quick-panel__nav">
          <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand  kt-notification-item-padding-x" role="tablist">
            <li className="nav-item active">
              <a className="nav-link active" data-toggle="tab" href="#kt_quick_panel_tab_notifications" role="tab">Feed</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#kt_quick_panel_tab_logs" role="tab">Notes</a>
            </li>
          </ul>
        </div>
        <div className="kt-quick-panel__content">
          <div className="tab-content">
            <div className="tab-pane fade show kt-scroll active" id="kt_quick_panel_tab_notifications" role="tabpanel">
              <div className="kt-notification">
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon2-crisp-icons kt-font-success"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      Digital Marketing (Airbnb)
                    </div>
                    <div className="kt-notification__item-time">
                      2 hrs ago
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon-notepad kt-font-brand"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      Sales Manager (Eir)
                    </div>
                    <div className="kt-notification__item-time">
                      3 hrs ago
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon-price-tag kt-font-danger"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      €299 from Eir
                    </div>
                    <div className="kt-notification__item-time">
                      3 hrs ago
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon-notepad kt-font-brand"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      Marketing Executive (Facebook)
                    </div>
                    <div className="kt-notification__item-time">
                      5 hrs ago
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon-price-tag kt-font-danger"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      €450 from Facebook
                    </div>
                    <div className="kt-notification__item-time">
                      6 hrs ago
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon2-crisp-icons kt-font-success"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      Product Manager (Tesco)
                    </div>
                    <div className="kt-notification__item-time">
                      12 hrs ago
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon2-crisp-icons kt-font-success"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      Junior Accountant (Skype)
                    </div>
                    <div className="kt-notification__item-time">
                      14 hrs ago
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon2-crisp-icons kt-font-success"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      Database Engineer (Spotify)
                    </div>
                    <div className="kt-notification__item-time">
                      18 hrs ago
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon-notepad kt-font-brand"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      Sales Manager (Eir)
                    </div>
                    <div className="kt-notification__item-time">
                      23 hrs ago
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon-notepad kt-font-brand"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      Transport Manager (Amazon)
                    </div>
                    <div className="kt-notification__item-time">
                      1 day ago
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon2-crisp-icons kt-font-success"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                      Digital Marketing (Airbnb)
                    </div>
                    <div className="kt-notification__item-time">
                      2 days ago
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="tab-pane fade kt-scroll" id="kt_quick_panel_tab_logs" role="tabpanel">
              <div className="kt-notification-v2">
                <a href="#" className="kt-notification-v2__item">
                  <span className="kt-media kt-media--circle kt-media--danger kt-margin-r-5 kt-margin-t-5">
                    <span>SC</span>
                  </span>
                  <div className="kt-notification-v2__itek-wrapper">
                    <div className="kt-notification-v2__item-title">
                      Digital Marketing (Airbnb)
                    </div>
                    <div className="kt-notification-v2__item-desc">
                      22 Sep 2020
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification-v2__item">
                  <span className="kt-media kt-media--circle kt-media--brand kt-margin-r-5 kt-margin-t-5">
                    <span>ML</span>
                  </span>
                  <div className="kt-notification-v2__itek-wrapper">
                    <div className="kt-notification-v2__item-title">
                      Airbnb
                    </div>
                    <div className="kt-notification-v2__item-desc">
                      26 Sep 2020
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification-v2__item">
                  <span className="kt-media kt-media--circle kt-media--warning kt-margin-r-5 kt-margin-t-5">
                    <span>SC</span>
                  </span>
                  <div className="kt-notification-v2__itek-wrapper">
                    <div className="kt-notification-v2__item-title">
                      Sales Manager (Eir)
                    </div>
                    <div className="kt-notification-v2__item-desc">
                      05 Oct 2020
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification-v2__item">
                  <span className="kt-media kt-media--circle kt-media--danger kt-margin-r-5 kt-margin-t-5">
                    <span>SC</span>
                  </span>
                  <div className="kt-notification-v2__itek-wrapper">
                    <div className="kt-notification-v2__item-title">
                      Digital Marketing (Airbnb)
                    </div>
                    <div className="kt-notification-v2__item-desc">
                      22 Sep 2020
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification-v2__item">
                  <span className="kt-media kt-media--circle kt-media--brand kt-margin-r-5 kt-margin-t-5">
                    <span>ML</span>
                  </span>
                  <div className="kt-notification-v2__itek-wrapper">
                    <div className="kt-notification-v2__item-title">
                      Airbnb
                    </div>
                    <div className="kt-notification-v2__item-desc">
                      26 Sep 2020
                    </div>
                  </div>
                </a>
                <a href="#" className="kt-notification-v2__item">
                  <span className="kt-media kt-media--circle kt-media--warning kt-margin-r-5 kt-margin-t-5">
                    <span>SC</span>
                  </span>
                  <div className="kt-notification-v2__itek-wrapper">
                    <div className="kt-notification-v2__item-title">
                      Sales Manager (Eir)
                    </div>
                    <div className="kt-notification-v2__item-desc">
                      05 Oct 2020
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default withRouter(QuickPanel);
