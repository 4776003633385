import React from "react"

import ImageEdit from "../common/ImageEdit"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"

export default class DomainSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      website: AuthManager.currentWebsite
    }
  }

  render() {
    let {
      website,
    } = this.state

    return (
      <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
        <div className="kt-heading kt-heading--md">{window.General.Branding.Name} Domain Settings</div>
        <div className="kt-form__section kt-form__section--first">
          <div className="kt-wizard-v2__form">

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">{window.General.Branding.Name} URL</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  className="form-control form-control-solid"
                  value={ Website.getDomain(website) }
                  disabled
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
