import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import WebsitesTable from './components/tables/WebsitesTable'

export default class Archived extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    return (
      <div className="row">
        <WebsitesTable
          title="Archived Sites"
          mode="shutdown"
        />
      </div>
    )
  }
}
