import React from "react";
import { withRouter } from "react-router-dom";

import CreateWebsiteModal from "./modal/CreateWebsiteModal";

import CreatePartnerModal from "./modal/CreatePartnerModal";

import AuthManager from "../../utils/AuthManager";
import Backend from "../../utils/Backend";
import Website from "../../utils/Website";

const LINK_DASHBOARD = {
  title: "Dashboard",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <rect fill="#000000" x="2" y="5" width="19" height="4" rx="1" />
      <rect
        fill="#000000"
        opacity="0.3"
        x="2"
        y="11"
        width="19"
        height="10"
        rx="1"
      />
    </g>
  ),
  url: "/",
};

const LINK_LIVE = {
  title: "Live",
  url: "/live",
};

const LINK_DEMO = {
  title: "Demo",
  url: "/demo",
};

const LINK_ARCHIVED = {
  title: "Archived",
  url: "/archived",
};

const LINK_PARTNER_SITES = {
  title: "Partner",
  url: "/partner-sites",
};

const LINK_TEMPLATE_SITES = {
  title: "Templates",
  url: "/templates",
};

const LINK_PARTNER_TEMPLATES = {
  title: "Templates",
  url: "/template-sites",
};

const LINK_DOMAINS = {
  title: "Domains",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M15,4 C15.5522847,4 16,4.44771525 16,5 L16,9 L14,9 L14,5 C14,4.44771525 14.4477153,4 15,4 Z M9,4 C9.55228475,4 10,4.44771525 10,5 L10,9 L8,9 L8,5 C8,4.44771525 8.44771525,4 9,4 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M13,16.9291111 L13,22 L11,22 L11,16.9291111 C7.60770586,16.4438815 5,13.5264719 5,10 L5,9 L19,9 L19,10 C19,13.5264719 16.3922941,16.4438815 13,16.9291111 Z"
        fill="#000000"
      />
    </g>
  ),
  url: "/domains",
};

const LINK_COUPONS = {
  title: "Coupons",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M7.70696074,11.6465196 L4.15660341,9.75299572 C3.96167938,9.64903623 3.88793804,9.40674327 3.99189753,9.21181925 C4.08361072,9.03985702 4.28590727,8.95902234 4.47087102,9.0204286 L9.57205231,10.7139738 L7.70696074,11.6465196 Z M12.7322989,14.3267 L16.3686753,12.9703901 L18.6316817,13.7216874 L18.6299527,13.7225513 C20.0084876,14.1925077 21,15.4985341 21,17.0361406 C21,18.9691372 19.4329966,20.5361406 17.5,20.5361406 C15.5670034,20.5361406 14,18.9691372 14,17.0361406 C14,16.3880326 14.176158,15.7810686 14.4832056,15.2605169 L12.7322989,14.3267 Z M17.5,15.5361406 C16.6715729,15.5361406 16,16.2077134 16,17.0361406 C16,17.8645677 16.6715729,18.5361406 17.5,18.5361406 C18.3284271,18.5361406 19,17.8645677 19,17.0361406 C19,16.2077134 18.3284271,15.5361406 17.5,15.5361406 Z"
        fill="#000000"
        fill-rule="nonzero"
        opacity="0.3"
      />
      <path
        d="M17.5,9 C18.3284271,9 19,8.32842712 19,7.5 C19,6.67157288 18.3284271,6 17.5,6 C16.6715729,6 16,6.67157288 16,7.5 C16,8.32842712 16.6715729,9 17.5,9 Z M14.4832056,9.27562366 C14.176158,8.75507197 14,8.14810794 14,7.5 C14,5.56700338 15.5670034,4 17.5,4 C19.4329966,4 21,5.56700338 21,7.5 C21,9.03760648 20.0084876,10.3436328 18.6299527,10.8135893 L18.6316817,10.8144531 L4.47087102,15.515712 C4.28590727,15.5771182 4.08361072,15.4962835 3.99189753,15.3243213 C3.88793804,15.1293973 3.96167938,14.8871043 4.15660341,14.7831448 L14.4832056,9.27562366 Z"
        fill="#000000"
        fill-rule="nonzero"
      />
    </g>
  ),
  url: "/coupons",
};

const LINK_PACKAGES = {
  title: "Sign Up Links",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.3476862,4.32173209 11.9473121,4.11839309 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 Z"
        fill="#000000"
      />
    </g>
  ),
  url: "/packages",
};

const LINK_COMPANIES = {
  title: "Clients",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
        fill="#000000"
        fill-rule="nonzero"
        opacity="0.3"
      />
      <path
        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
        fill="#000000"
        fill-rule="nonzero"
      />
    </g>
  ),
  url: "/clients",
};

const LINK_PARTNERS = {
  title: "Partners",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M9,10 L9,19 L10.1525987,19.3841996 C11.3761964,19.7920655 12.6575468,20 13.9473319,20 L17.5405883,20 C18.9706314,20 20.2018758,18.990621 20.4823303,17.5883484 L21.231529,13.8423552 C21.5564648,12.217676 20.5028146,10.6372006 18.8781353,10.3122648 C18.6189212,10.260422 18.353992,10.2430672 18.0902299,10.2606513 L14.5,10.5 L14.8641964,6.49383981 C14.9326895,5.74041495 14.3774427,5.07411874 13.6240179,5.00562558 C13.5827848,5.00187712 13.5414031,5 13.5,5 L13.5,5 C12.5694044,5 11.7070439,5.48826024 11.2282564,6.28623939 L9,10 Z"
        fill="#000000"
      />
      <rect
        fill="#000000"
        opacity="0.3"
        x="2"
        y="9"
        width="5"
        height="11"
        rx="1"
      />
    </g>
  ),
  url: "/partners",
};

const LINK_CONTACTS = {
  title: "Leads",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
        fill="#000000"
        fill-rule="nonzero"
      />
      <path
        d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
        fill="#000000"
        fill-rule="nonzero"
        opacity="0.3"
      />
    </g>
  ),
  url: "/leads",
};

const LINK_SUBMISSIONS = {
  title: "Leads",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
        fill="#000000"
        fill-rule="nonzero"
      />
      <path
        d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
        fill="#000000"
        fill-rule="nonzero"
        opacity="0.3"
      />
    </g>
  ),
  url: "/leads",
};

const LINK_REPORTS = {
  title: "Reports",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
        fill="#000000"
        fill-rule="nonzero"
      />
      <path
        d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
        fill="#000000"
        fill-rule="nonzero"
        opacity="0.3"
      />
    </g>
  ),
  url: "/reports",
};

const LINK_SETTINGS = {
  title: "Settings",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
        fill="#000000"
      />
    </g>
  ),
  url: "/settings",
};

const LINK_DOMAIN_HANDLING = {
  title: "Domain",
  url: "/domain/xyz",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M5,6 L19,6 C19.5522847,6 20,6.44771525 20,7 L20,17 L4,17 L4,7 C4,6.44771525 4.44771525,6 5,6 Z"
        fill="#000000"
      />
      <rect
        fill="#000000"
        opacity="0.3"
        x="1"
        y="18"
        width="22"
        height="1"
        rx="0.5"
      />
    </g>
  ),
};

const LINK_TEAM = {
  title: "Team",
  url: "/team",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
        fill="#000000"
        opacity="0.3"
      />
    </g>
  ),
};

const LINK_CHATS = {
  title: "Personal Web Designer",
  url: "/chats",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L5,18 C3.34314575,18 2,16.6568542 2,15 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z M6.16794971,10.5547002 C7.67758127,12.8191475 9.64566871,14 12,14 C14.3543313,14 16.3224187,12.8191475 17.8320503,10.5547002 C18.1384028,10.0951715 18.0142289,9.47430216 17.5547002,9.16794971 C17.0951715,8.86159725 16.4743022,8.98577112 16.1679497,9.4452998 C15.0109146,11.1808525 13.6456687,12 12,12 C10.3543313,12 8.9890854,11.1808525 7.83205029,9.4452998 C7.52569784,8.98577112 6.90482849,8.86159725 6.4452998,9.16794971 C5.98577112,9.47430216 5.86159725,10.0951715 6.16794971,10.5547002 Z"
        fill="#000000"
      />
    </g>
  ),
};

const LINK_PARTNER_LEADS = {
  title: "Partner Leads",
  url: "/partner-leads",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L5,18 C3.34314575,18 2,16.6568542 2,15 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z M6.16794971,10.5547002 C7.67758127,12.8191475 9.64566871,14 12,14 C14.3543313,14 16.3224187,12.8191475 17.8320503,10.5547002 C18.1384028,10.0951715 18.0142289,9.47430216 17.5547002,9.16794971 C17.0951715,8.86159725 16.4743022,8.98577112 16.1679497,9.4452998 C15.0109146,11.1808525 13.6456687,12 12,12 C10.3543313,12 8.9890854,11.1808525 7.83205029,9.4452998 C7.52569784,8.98577112 6.90482849,8.86159725 6.4452998,9.16794971 C5.98577112,9.47430216 5.86159725,10.0951715 6.16794971,10.5547002 Z"
        fill="#000000"
      />
    </g>
  ),
};

export const MENU_ITEMS = [
  LINK_DASHBOARD,
  LINK_LIVE,
  LINK_DEMO,
  LINK_ARCHIVED,
  LINK_TEMPLATE_SITES,
  LINK_DOMAINS,
  LINK_COMPANIES,
  LINK_COUPONS,
  LINK_PACKAGES,
  LINK_PARTNERS,
  LINK_REPORTS,
  LINK_SETTINGS,
  LINK_CONTACTS,
  LINK_SUBMISSIONS,
  LINK_PARTNER_TEMPLATES,
  LINK_DOMAIN_HANDLING,
  LINK_PARTNER_SITES,
  LINK_CHATS,
  LINK_PARTNER_LEADS
];

const SECTIONS = [
  {
    type: null,
    title: null,
    links: [LINK_DASHBOARD],
  },


  {
    type: "menu",
    icon: (
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          d="M6,5 L18,5 C19.6568542,5 21,6.34314575 21,8 L21,17 C21,18.6568542 19.6568542,20 18,20 L6,20 C4.34314575,20 3,18.6568542 3,17 L3,8 C3,6.34314575 4.34314575,5 6,5 Z M5,17 L14,17 L9.5,11 L5,17 Z M16,14 C17.6568542,14 19,12.6568542 19,11 C19,9.34314575 17.6568542,8 16,8 C14.3431458,8 13,9.34314575 13,11 C13,12.6568542 14.3431458,14 16,14 Z"
          fill="#000000"
        />
      </g>
    ),

    title: "Sites",
    links: [LINK_LIVE, LINK_DEMO, LINK_ARCHIVED],
  },

  {
    type: null,
    title: null,
    links: [],
  },
  {
    type: null,
    title: null,
    links: [],
  },
];

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);

    let sections = SECTIONS;

    if (AuthManager.isPartner()) {
      sections[1].links.push(LINK_PARTNER_TEMPLATES);
      sections[2].links = [
        LINK_COMPANIES,
        LINK_SUBMISSIONS,
        LINK_PACKAGES,
        LINK_COUPONS,
      ];

      sections[3].links = [LINK_DOMAIN_HANDLING, LINK_TEAM, LINK_SETTINGS];
    } else {
      sections[1].links.splice(2, 0, LINK_PARTNER_SITES);
      if (AuthManager.isPlatformAdmin()) {
        sections[1].links.push(LINK_TEMPLATE_SITES);
      }
      sections[2].links = [
        LINK_COMPANIES,
        LINK_DOMAINS,
        LINK_PACKAGES,
        LINK_COUPONS,
        LINK_PARTNERS,
        LINK_PARTNER_LEADS,
        LINK_REPORTS,
      ];
    }

    this.state = {
      sections,
    };
  }

  _onEditWebsiteClicked() {
    let website = AuthManager.currentUser.company.websites.find(
      (website) =>
        website.id === AuthManager.currentUser.company.partner.website
    );

    this.setState({ loadingHash: true });
    let url = Website.getSiteUrl(website) + "?edit=true";
    let editWebsiteTab = window.open();
    Backend.createLoginHash(website)
      .then((hash) => {
        editWebsiteTab.location.href = url + "&h=" + hash.value;
        this.setState({ loadingHash: false });
      })
      .catch((error) => {
        editWebsiteTab.location.href = url;
        this.setState({ loadingHash: false });
      });
  }

  _goTo(url) {
    this.props.history.push(url);
  }

  _renderSections() {
    let { sections } = this.state;

    return (
      <div
        className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        id="kt_aside_menu_wrapper"
      >
        <div
          id="kt_aside_menu"
          className="kt-aside-menu"
          data-ktmenu-vertical="1"
          data-ktmenu-scroll="1"
          data-ktmenu-dropdown-timeout="500"
        >
          <ul className="kt-menu__nav">
            <div className="desktop-buttons">
              <li className="kt-menu__item">
                <a
                  Href="#"
                  className="btn btn-brand side-margin-20 m-b-20"
                  onClick={() => {
                    this.setState({ showCreateWebsiteModal: true });
                  }}
                >
                  Create Site
                </a>
              </li>
              {AuthManager.isPlatformStaff() && (
                <li className="kt-menu__item">
                  <a
                    Href="#"
                    className="btn btn-outline-brand side-margin-20 m-b-20"
                    onClick={() => {
                      this.setState({ showCreatePartnerModal: true });
                    }}
                  >
                    Create Partner
                  </a>
                </li>
              )}
              {AuthManager.isPartner() && (
                <li className="kt-menu__item">
                  <a
                    Href="#"
                    className="btn btn-outline-brand side-margin-20 m-b-20"
                    onClick={() => this._onEditWebsiteClicked()}
                  >
                    View / Edit Promo Site
                  </a>
                </li>
              )}
            </div>
            {sections.map((section, sectionIndex) => {
              return (
                <>
                  {section.type == "menu" && (
                    <li
                      class="kt-menu__item  kt-menu__item--submenu"
                      aria-haspopup="true"
                      data-ktmenu-submenu-toggle="hover"
                    >
                      <a
                        href="javascript:;"
                        class="kt-menu__link kt-menu__toggle"
                      >
                        <span className="kt-menu__link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                            className="kt-svg-icon"
                          >
                            {section.icon}
                          </svg>
                        </span>
                        <span class="kt-menu__link-text">{section.title}</span>
                        <i class="kt-menu__ver-arrow la la-angle-right"></i>
                      </a>

                      <div class="kt-menu__submenu ">
                        <span class="kt-menu__arrow"></span>
                        <ul class="kt-menu__subnav">
                          {section.links.map((link, linkIndex) => {
                            let className = "kt-menu__item";
                            if (link.url === window.location.pathname) {
                              className += " kt-menu__item--active";
                            }
                            return (
                              <li className={className} aria-haspopup="true">
                                <a
                                  className="kt-menu__link"
                                  onClick={() =>
                                    this.props.history.push(link.url)
                                  }
                                >
                                  <i class="kt-menu__link-bullet kt-menu__link-bullet--dot">
                                    <span></span>
                                  </i>
                                  <span className="kt-menu__link-text">
                                    {link.title}
                                  </span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  )}

                  {section.type != "menu" && section.title && (
                    <li className="kt-menu__section ">
                      <h4 className="kt-menu__section-text">{section.title}</h4>
                      <i className="kt-menu__section-icon flaticon-more-v2"></i>
                    </li>
                  )}
                  {section.type != "menu" &&
                    section.links.map((link, linkIndex) => {
                      let className = "kt-menu__item";
                      if (link.url === window.location.pathname) {
                        className += " kt-menu__item--active";
                      }
                      return (
                        <li className={className} aria-haspopup="true">
                          <a
                            className="kt-menu__link"
                            onClick={() => this.props.history.push(link.url)}
                          >
                            <span className="kt-menu__link-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                className="kt-svg-icon"
                              >
                                {link.icon}
                              </svg>
                            </span>
                            <span className="kt-menu__link-text">
                              {link.title}
                            </span>
                          </a>
                        </li>
                      );
                    })}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  _renderCreateWebsiteModal() {
    let { showCreateWebsiteModal } = this.state;

    if (!showCreateWebsiteModal) {
      return null;
    }

    return (
      <CreateWebsiteModal
        show={showCreateWebsiteModal}
        onCancel={() => this.setState({ showCreateWebsiteModal: false })}
        onCreated={(website) =>
          this.setState({ showCreateWebsiteModal: false })
        }
      />
    );
  }

  _renderCreatePartnerModal() {
    let { showCreatePartnerModal } = this.state;

    if (!showCreatePartnerModal) {
      return null;
    }

    return (
      <CreatePartnerModal
        show={showCreatePartnerModal}
        onCancel={() => this.setState({ showCreatePartnerModal: false })}
        onCreated={(partner) =>
          this.setState({ showCreatePartnerModal: false })
        }
      />
    );
  }

  render() {
    let { showCreateWebsiteModal, showCreatePartnerModal } = this.state;

    let logo = AuthManager.currentUser.company?.logo?.original;
    return (
      <>
        {/* <button className="kt-aside-close " id="kt_aside_close_btn">
          <i className="la la-close">
          </i>
        </button> */}
        <div
          className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
          id="kt_aside"
        >
          <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
            <div className="kt-aside__brand-logo">
              <a onClick={() => {}}>
                <img
                  alt="Logo"
                  src={logo || window.General.Branding.Logo}
                  height="30px"
                />
              </a>
            </div>
            <div className="kt-aside__brand-tools">
              <button
                className="kt-aside__brand-aside-toggler"
                id="kt_aside_toggler"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="kt-svg-icon"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "
                      />
                      <path
                        d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="kt-svg-icon"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
              </button>
              {/*<button className="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left" id="kt_aside_toggler"><span></span></button> */}
            </div>
          </div>

          {this._renderSections()}

          {this._renderCreateWebsiteModal()}

          {this._renderCreatePartnerModal()}
        </div>
      </>
    );
  }
}

export default withRouter(LeftMenu);
