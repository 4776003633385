import React from 'react'

import PartnerLeadsTable from "./components/tables/PartnerLeadsTable";


export default class PartnerLeads extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <div className="row">
        <PartnerLeadsTable/>
      </div>
    )

  }
}
