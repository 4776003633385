import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import Card from './components/Card'

import WebsitesTable from './components/tables/WebsitesTable'

import Chart from './components/chart/Chart'
import GraphStatCard from './components/chart/GraphStatCard'

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingStats: true,
      loadingGraphs: true,
      stats: []
    }
  }

  componentDidMount(){
    Backend.getStats()
    .then(stats => {
      let websiteStats = stats.websites
      let data = [
        {
          title: websiteStats.live_no,
          subtitle: "Live",
          color: "#333333",
          type: "brand"
        },
        {
          title: websiteStats.demo_no,
          subtitle: "Demo",
          color: "#333333",
          type: "danger"
        },
        {
          title: "€"+(websiteStats.recurring_amount / 100.0),
          subtitle: "Total Monthly Revenue",
          color: "#333333",
          type: "success",
        },
        {
          title: "€"+(websiteStats.once_off_amount / 100.0),
          subtitle: "Total Once Off Revenue",
          color: "#333333",
          type: "success",
        },
      ]

      let siteStats = {
        title: "Sites",
        data: [23, 14, 18, 19, 30, 40, 20, 19, 5, 22],
        labels: ["01 Aug", "02 Aug", "03 Aug", "04 Aug", "05 Aug", "06 Aug", "07 Aug", "08 Aug", "09 Aug", "10 Aug"],
        color: "#333333"
      }

      let revenueStats = {
        title: "New Revenue",
        data: [23, 14, 18, 19, 30, 40, 20, 19, 5, 22],
        labels: ["01 Aug", "02 Aug", "03 Aug", "04 Aug", "05 Aug", "06 Aug", "07 Aug", "08 Aug", "09 Aug", "10 Aug"],
        color: "#333333"
      }

      this.setState({
        stats: data,
        graphs: [siteStats, revenueStats],
        loadingStats: false,
      })

      setTimeout(() => {
        this.setState({
          loadingGraphs: false
        })
      }, 300)
    })
    .catch(error => {
      console.log("ddd", error)
    })
  }

  _renderStats(){
    let { stats } = this.state
    return stats.map((stat, index) => {
      return (
        <div className="col-lg-3">
          <div className={`kt-portlet kt-portlet--border-bottom-dark`}>
            <div className="kt-portlet__body kt-portlet__body--fluid">
              <div className="kt-widget26">
                <div className="kt-widget26__content">
                  <span className="kt-widget26__number">{ stat.title }</span>
                  <span className="kt-widget26__desc">{ stat.subtitle }</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  _renderGraphs(){
    let {
      graphs,
      loadingGraphs,
    } = this.state

    if(loadingGraphs){
      return null
    }

    return graphs.map(graph => {
      return (
        <Card
          title={ graph.title }
          loading={false}
        >
          <Chart
            data={ graph.data }
            labels={ graph.labels }
            color={ graph.color }
            backgroundColor={ graph.color }
            minimal={false}
            type="bar"
          />
        </Card>
      )
    })
  }

  render() {

    let {
      stats,
      selectedJob,
      showJobEditModal
    } = this.state

    return (
      <>
        <div className="row">
          { this._renderStats() }
        </div>
        <div className="row">
          <WebsitesTable
            demo="false"
            showPagination={false}
            title="Latest 20 Sites"
          />
        </div>
        {/*}
        <div className="row">
          { this._renderGraphs() }
        </div>
        */}
      </>
    )
  }
}
