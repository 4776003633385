import React from "react"

import moment from 'moment'

import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable"

import ConfirmModal from '../modal/ConfirmModal'
import PartnerLeadModal from "../modal/PartnerLeadModal";

import AuthManager from '../../../utils/AuthManager'
import General from '../../../utils/General'
import Currency from "../../../utils/Currency";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

const STATUSES = [
  {
    label: "All",
    value: ""
  },
  {
    label: "New",
    value: "new"
  },
  {
    label: "Replied",
    value: "replied"
  },
  {
    label: "Closed",
    value: "closed"
  },
  {
    label: "Live",
    value: "live"
  },
  {
    label: "Archived",
    value: "archive"
  }
]

class PartnerLeadsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      filters: STATUSES
    }

    this.table = React.createRef()
  }

  _deleteLead(lead){
    return Backend.deletePartnerLead(lead)
    .then(() => {
      this.table.current.refresh()
      Notify.success("Deleted Successfully")
      this.setState({
        selectedLead: null,
        isDeletingLead: false,
        showDeleteLeadModal: false
       })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({
        selectedLead: null,
        isDeletingLead: false
      })
    })
  }

  _archiveLead(lead){
    lead.status = "archive"
    this.setState({isArchivingLead: true })
    return Backend.updatePartnerLead(lead)
    .then(() => {
      this.table.current.refresh()
      Notify.success("Archived Successfully")
      this.setState({
        selectedLead: null,
        isArchivingLead: false,
        showArchiveLeadModal: false
       })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({
        selectedLead: null,
        isArchivingLead: false
      })
    })
  }

  _getColumns() {
    let {
    } = this.state

    return [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: lead => moment(lead.created_at).format('DD/MM/YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Business',
        id: "website_name",
        accessor: lead => General.toTitleCase(lead.website_name),
      },
      {
        Header: 'Name',
        id: "contact_name",
        accessor: lead => lead.contact_name,
      },
      {
        Header: 'Monthly',
        id: "data.monthly_price",
        accessor: lead => Currency.toCurrency('€',lead.data?.monthly_price),
      },
      {
        Header: 'Sites',
        accessor: "data.no_websites",
        exportable: false
      },
      {
        Header: 'Customers',
        accessor: "data.client_base",
        exportable: false
      },
      {
        Header: 'Support',
        id: 'data.customer_support',
        exportable: false,
        Cell: rowInfo => {
          let lead = rowInfo.original

          return lead.data?.customer_support ? 'Yes' : 'No'
        }
      },
      {
        Header: 'App',
        id: 'data.add_ons.mobile_app',
        exportable: false,
        Cell: rowInfo => {
          let lead = rowInfo.original

          return lead.data?.add_ons.mobile_app ? 'Yes' : 'No'
        }
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: rowInfo => {
          let lead = rowInfo.original

          return General.toTitleCase(lead.status)
        }
      },
      {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let lead = rowInfo.original
          return (
            <>
              <span>
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                  onClick={() => this.setState({ selectedLead: lead, showLeadModal: true })}
                  title="Edit"
                >
                  <i className="la la-edit"/>
                </a>
                {
                  lead.status !== "archive" &&
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                    onClick={() => this.setState({ selectedLead: lead, showArchiveLeadModal: true })}
                    title="Archive"
                  >
                    <i className="la la-power-off"/>
                  </a>
                }
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                  onClick={() => this.setState({ selectedLead: lead, showDeleteLeadModal: true })}
                  title="Delete"
                >
                  <i className="la la-trash"/>
                </a>
              </span>
            </>
          )
        }
      }
    ]
  }

  _renderDetail(leadData){
    return (
      <>
        <div style={{ background: '#f4f5fb' }}>
          <div style={{ margin: 20, padding: 20, background: 'white' }}>
            <h5>Set Up Fee</h5>
            <p>{Currency.toCurrency('€',leadData.data?.setup_price)}</p>
            <h5>Monthly License</h5>
            <p>{Currency.toCurrency('€',leadData.data?.monthly_price)}</p>
            <h5>Support</h5>
            <p>{Currency.toCurrency('€',leadData.data?.support_price)}</p>
            <h5>Customer Market</h5>
            <p>{leadData.data?.target_market}</p>
            <h5>Add Ons</h5>
            <ul style={{listStyle: 'none', padding: 0}}>
              {
                Object.keys(leadData.data?.add_ons).map((key) => {
                  return (
                    <><li><b>{General.snakeCaseToTitleCase(key)}:</b> {leadData.data?.add_ons[key] ? 'Yes' : 'No'}</li></>
                  )
                })
              }
            </ul>
            <h5>Platforms</h5>
            <ul style={{listStyle: 'none', padding: 0}}>
              {
                Object.keys(leadData.data?.platforms).map((key) => {
                  return (
                    <><li><b>{General.snakeCaseToTitleCase(key)}:</b> {leadData.data?.platforms[key] ? 'Yes' : 'No'}</li></>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </>
    )
  }

  render() {
    let {
      filters,
      websiteId,
      selectedLead,
      showLeadModal,
      isDeletingLead,
      isArchivingLead,
      showDeleteLeadModal,
      showArchiveLeadModal,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Leads}
          title="Partner Leads"
          columns={columns}
          filters={filters}
          searchFilterName="status"
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showPaginationTop={false}
          exportButtonsEnabled={false}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
          SubComponent={(row) => {
            return this._renderDetail(row.original)
          }}
        />
        {
          showLeadModal &&
          <PartnerLeadModal
            show={showLeadModal}
            lead={selectedLead}
            onUpdated={() => {
              this.table.current.refresh()
              this.setState({selectedLead: null, showLeadModal: false})
            }}
            onHide={() => this.setState({selectedLead: null, showLeadModal: false})}
          />
        }
        {
          showDeleteLeadModal &&
          <ConfirmModal
            show={showDeleteLeadModal}
            loading={isDeletingLead}
            message="This cannot be undone"
            cancelButtonText="Cancel"
            confirmButtonText="Delete"
            onCancel={() => this.setState({ selectedLead: null, showDeleteLeadModal: false})}
            onConfirm={() => this._deleteLead(selectedLead)}
          />
        }
        {
          showArchiveLeadModal &&
          <ConfirmModal
            show={showArchiveLeadModal}
            loading={isArchivingLead}
            message="This lead will only be visible via filtering to the 'Archived' status."
            cancelButtonText="Cancel"
            confirmButtonText="Archive"
            onCancel={() => this.setState({ selectedLead: null, showArchiveLeadModal: false})}
            onConfirm={() => this._archiveLead(selectedLead)}
          />
        }
      </>
    )
  }
}

export default withRouter(PartnerLeadsTable);
