import React from 'react'

import AsyncPaginate from 'react-select-async-paginate';

import FetchHelper from '../../utils/FetchHelper'

export default class AsyncSelect extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      endpoint: props.endpoint,
      search: '',
      prevSearch: '',
      items: [],
      nextPageUrl: null,
      value: this.props.value,
    }
  }

  _loadOptions(search, prevOptions){
    let {
      endpoint,
      prevSearch,
      nextPageUrl,
      items
    } = this.state

    let url = `${endpoint}?search_term=${search}&order_by=name`
    let nextPage = false
    if(search === prevSearch && nextPageUrl){
      url = nextPageUrl
      nextPage = true
    }

    return FetchHelper.get(url)
    .then(response => {
      let newOptions = this.props.getOptions(response.results)
      if(nextPage){
        items = [
          ...items,
          ...response.results
        ]
      }
      else{
        items = response.results
      }

      this.setState({
        items,
        nextPageUrl: response.next,
        prevSearch: search
      })
      return {
        options: newOptions,
        hasMore: response.next != null
      }
    })
  };

  render(){
    return (
      <AsyncPaginate
        value={this.state.value}
        loadOptions={this._loadOptions.bind(this)}
        debounceTimeout={300}
        onChange={option => {
          this.setState({ value: option })
          this.props.onSelected(option.data)
        }}
      />
    )
  }
}
