import React from 'react'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/plugins/react-toastify/override.css'

export default class Notify {

  static success(message="Success", icon=null, onClick){
    toast.success(message, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
      pauseOnFocusLoss: false,
    });
  }

  static error(message="An unexpected error occured, please try again"){
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
      pauseOnFocusLoss: false,
    });
  }

  static message(message, onClick){

    let sender = message.sender.user || message.sender.visitor

    let title = sender.first_name ? `${sender.first_name} ${sender.last_name || ""}` : `Visitor #${sender.id}`
    toast((
      <div className="notification-message">
        <div className="notification-message-head">
          <span className="font-weight-bold">{title}</span>
        </div>
        <div className="notification-message-body">
          <span>{message.text}</span>
        </div>
      </div>
    ), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
      pauseOnFocusLoss: false,
      className: "notification-message-container",
      onClick: onClick
    });
  }
}
