import React from "react"

import Div from "../layouts/Div"

import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"
import Backend from "../../../utils/Backend"

export default class AccountSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      terms_url: AuthManager.currentUser.company.partner.terms_url
    }
  }

  isFormValid(){
    let {
      terms_url
    } = this.state

    let error = null

    if(!terms_url){
      error = "Please enter a valid terms url"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _update(){
    let {
      terms_url
    } = this.state

    if(!this.isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.updatePartnerTerms(AuthManager.currentUser.company.id, terms_url)
    .then(() => {
      AuthManager.currentUser.company.partner.terms_url = terms_url
      this.setState({
        loading: false
      })
      Notify.success("Terms & Conditions URL updated successfully!")
    })
    .catch(error => {
      this.setState({
        loading: false
      })
      Notify.error(error.message)
    })
  }

  _handleChange(e) {
    this.setState({ [e.target.name]: e.target.value, error: null })
  }

  render() {
    let {
      loading,
      terms_url
    } = this.state

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
        >
        <div className="kt-heading kt-heading--md">Terms & Conditions</div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              <p>Please Enter Your Terms & Conditions URL</p>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">URL</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    name="terms_url"
                    defaultValue={ terms_url }
                    onChange={e => this._handleChange(e)}
                  />
                  <p>Please include http:// if your terms are on an external url</p>
                </div>
              </div>

            </div>
          </div>

        </Div>
        <div className="kt-form__actions pull-right">
          <button
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            disabled={loading}
            onClick={() => this._update()}
          >
            Save Changes
          </button>
        </div>
      </>
    )
  }
}
