import React from 'react'

import TextField from "@material-ui/core/TextField";
import onClickOutside from "react-onclickoutside"
import {Draggable} from "react-beautiful-dnd";

class IdleList extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      index: props.index,
      showInput: false,
      newList: {}
    }
  }

  handleClickOutside = evt => {
    let {
      showInput
    } = this.state

    if(showInput){
      this.setState({showInput: false})
    }
  };

  render() {
    let { index, newList, showInput } = this.state

    return (
      <Draggable draggableId={"idle"} key={"idle"} index={index} isDragDisabled={true}>
        {(provided) => (
          <div
            className="column-container kanban-board"
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <header
              className={
                'column-title kanban-board-header align-items-center justify-content-between kanban-list-idle ' + (showInput ? "show-form " : "")
              }
              {...provided.dragHandleProps}
              style={{
                cursor: "pointer"
              }}
              onClick={() => this.setState({showInput: true})}
            >
              <div
                className="kanban-title-board col-10"
                style={{color: '#181C32'}}
              >
                {
                  !showInput &&
                  <>
                    <i className="la la-plus"></i> Add Another List
                  </>
                }
                { showInput &&
                  <>
                    <TextField
                      label={"Enter list title..."}
                      fullWidth
                      variant={"outlined"}
                      onChange={e => {
                        let newList = {name: e.target.value};
                        this.setState({newList})
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter')
                        this.setState({showInput: false}, () => this.props.onAddListClicked(newList))
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-primary font-weight-bolder mt-2"
                      disabled={!newList.name}
                      onClick={() => {
                        this.setState({showInput: false}, () => this.props.onAddListClicked(newList))
                      }}
                    >
                      Add
                    </button>
                  </>
                }
              </div>
            </header>
          </div>
        )}
      </Draggable>
    )
  }
}

IdleList.defaultProps = {}

export default onClickOutside(IdleList)
