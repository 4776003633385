import moment from 'moment'

export default class Time {
  static formatDate(value){
    const date = new moment(value)
    return date.format("DD-MM-YYYY")
  }

  static formatFromNow(value){
    const date = new moment(value)

    const now = new moment()

    const diffInDays = date.diff(now, "days")
    const diffInHours = date.diff(now, "hours") % 24
    const diffInMinutes = date.diff(now, "minutes") % 60
    const diffInSeconds = date.diff(now, "seconds") % 60

    let timeFromNow = diffInSeconds + " seconds"

    if(diffInMinutes > 0){
      let minutes = diffInMinutes > 1 ? "minutes" : "minute"
      timeFromNow = diffInMinutes + " minutes and " + timeFromNow
    }

    if(diffInHours > 0){
      let hours = diffInHours > 1 ? "hours" : "hour"
      timeFromNow = diffInHours + " " + hours + " " + timeFromNow
    }

    if(diffInDays > 0){
      let days = diffInDays > 1 ? "days" : "day"
      timeFromNow = diffInDays + " " + days + " " + timeFromNow
    }

    return timeFromNow
  }
}
