import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Div from '../layouts/Div'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"


export default class CreateWebsiteModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      transfer: props.transfer,
      transfer: {}
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e) {
    let transfer = this.state.transfer
    transfer[e.target.name] = e.target.value
    this.setState({ transfer })
  }

  _isFormValid(){
    let {
      transfer
    } = this.state

    let {
      email,
      first_name,
      last_name,
      company_name,
      password,
    } = transfer

    let error = null
    if(!first_name || first_name === ""){
      error = "Please enter a valid first name"
    }
    else if(!last_name || last_name == ""){
      error = "Please enter a valid last name"
    }
    else if(!company_name || company_name == ""){
      error = "Please enter a company name"
    }
    else if(!email || email == ""){
      error = "Please enter an email"
    }
    else if(!password || password == null){
      error = "Please enter a password"
    }

    if(error){
      alert(error)
      return false
    }

    return true
  }

  _onTransferClicked(){
    let {
      website,
      transfer
    } = this.state

    if(!this._isFormValid()){
      return
    }

    transfer.website = website.id

    this.setState({ loading: true })
    Backend.createTransfer(transfer)
    .then(transfer => {
      this.props.onTransferred()
      this.setState({
        loading: false
      })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      loading,
      transfer
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transfer Site</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Div
            disabled={loading}
            spinner={true}
          >
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                name="first_name"
                className="form-control"
                value={ transfer.first_name }
                onChange={e => this._handleChange(e)}
              />
            </div>

            <div className="form-group">
              <label>Last Name</label>
              <input
                type="text"
                name="last_name"
                className="form-control"
                value={ transfer.last_name }
                onChange={e => this._handleChange(e)}
              />
            </div>

            <div className="form-group">
              <label>Client</label>
              <input
                type="text"
                name="company_name"
                className="form-control"
                value={ transfer.company_name }
                onChange={e => this._handleChange(e)}
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="emal"
                name="email"
                className="form-control"
                value={ transfer.email }
                onChange={e => this._handleChange(e)}
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                className="form-control"
                value={ transfer.password }
                onChange={e => this._handleChange(e)}
              />
            </div>
          </Div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._onTransferClicked()}
          >
            Transfer
          </button>

        </Modal.Footer>

      </Modal>
    )
  }
}

CreateWebsiteModal.defaultProps = {
    loading: false,
}
