import React from 'react'

import General from "../../../utils/General"

export default class CRMListHeader extends React.Component {
  render() {
    let { list } = this.props
    return (
      <>
        <div
          class="kanban-title-board col-9"
          style={{ color: list.color }}
        >
          <span
            class="label label-success mr-2 font-weight-bold"
            style={{ backgroundColor: list.color + 'cc' }} // 80% opacity
          >
            {list.cards.length}
          </span>
          { General.toTitleCase(list.name) }
        </div>

        {/*
          <a onClick={() => this.props.onAddCardClicked(list)} class="btn btn-icon btn-sm">
            <span className="bg-color default" style={{ backgroundColor: list.color + 'cc' }} // 80% opacity
            ></span>
            <span className="bg-color hover" style={{ backgroundColor: list.color + '80' }} // 50% opacity
            ></span>
            <span class="svg-icon svg-icon-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect
                    className="fill-white"
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  />
                  <rect
                    className="fill-white"
                    opacity="0.9"
                    transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  />
                </g>
              </svg>
            </span>
          </a>
        */}
      </>
    )
  }
}

CRMListHeader.defaultProps = {}
