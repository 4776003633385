import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend"
import Switch from "react-switch";
import SmartList from "../common/SmartList";
import TextField from "@material-ui/core/TextField";

export default class FormFieldModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      form: props.form,
      show: props.show,
      loading: false,
      error: null,
      formField: JSON.parse(JSON.stringify(props.formField))
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      ...nextProps,
      formField: JSON.parse(JSON.stringify(nextProps.formField))
    })
  }

  _handleChange(e) {
    let formField = this.state.formField
    formField[e.target.name] = e.target.value
    this.setState({ formField, error: null })
  }

  _isFormValid(){
    let {
      title,
      url
    } = this.state.formField

    let error = null
    if(title == null || title.length === 0){
      error = "Please enter a valid label"
    }

    if(url){
      if(!url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)){
        error = "Please enter a valid url"
      }
    }

    if(error){
      this.setState({ error })
      return false
    }
    return true
  }

  _handleUpdateFormItem(){
    if(!this._isFormValid()){
      return
    }
    this._updateFormItem()
  }

  _updateFormItem(){
    let {
      formField
    } = this.state

    this.props.updateFormField(formField)
  }

  render() {
    let {
      form,
      show,
      loading,
      formField,
      error
    } = this.state

    if(!show) return null

    let type = formField.type

    return (
      <Modal
        show={show}
        backdrop={"static"}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Settings</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { error &&
            <div className="form-group row">
              <div className="col-lg-12 my-auto">
                <span className="error-message">{ error }</span>
              </div>
            </div>
          }

          <div className="form-group row">
            <div className="col-lg-12 my-auto">
              <TextField
                label={"Title"}
                fullWidth
                variant={"outlined"}
                value={ formField.title }
                onChange={event => {
                  formField.title = event.target.value
                  this.setState({ formField })
                }}
              />
            </div>
          </div>

          {
            (type === "boolean" && formField.is_required) &&
            <>
              <div className="form-group row">
                <div className="col-lg-12 my-auto">
                  <TextField
                    label={"Url"}
                    fullWidth
                    variant={"outlined"}
                    value={ formField.url }
                    onChange={event => {
                      formField.url = event.target.value || null
                      this.setState({ formField })
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-12 my-auto">
                  <TextField
                    label={"Error Message"}
                    fullWidth
                    variant={"outlined"}
                    value={ formField.error_message }
                    onChange={event => {
                      formField.error_message = event.target.value || null
                      this.setState({ formField })
                    }}
                  />
                </div>
              </div>
            </>
          }

          {
            type !== "boolean" &&
            <div className="form-group row align-items-center">
              <label className="col-lg-3 col-form-label">Required</label>
              <div className="col-lg-3">
                <Switch
                  className="react-switch c-switch"
                  onChange={checked => {
                    formField.is_required = checked
                    this.setState({ formField })
                  }}
                  checked={formField.is_required}
                  aria-labelledby="neat-label"
                  onColor="#333333"
                  offColor="#F3F6F9"
                  checkedIcon={null}
                  uncheckedIcon={null}
                  disabled={
                    type === "contact_first_name" ||
                    type === "contact_last_name" ||
                    type === "contact_email"
                  }
                />
              </div>
            </div>
          }

          {
            (type === "email" || type === "contact_email") &&
            <>
              <div className="form-group row align-items-center">
                <label className="col-lg-3 col-form-label">Send Email</label>
                <div className="col-lg-3">
                  <Switch
                    className="react-switch c-switch"
                    onChange={checked => {
                      formField.send_emails = checked
                      this.setState({ formField })
                    }}
                    checked={formField.send_emails}
                    aria-labelledby="neat-label"
                    onColor="#333333"
                    offColor="#F3F6F9"
                    checkedIcon={null}
                    uncheckedIcon={null}
                  />
                </div>
              </div>

              <div className="form-group row align-items-center">
                <label className="col-lg-3 col-form-label">Unique</label>
                <div className="col-lg-3">
                  <Switch
                    className="react-switch"
                    onChange={checked => {
                      formField.is_unique = checked
                      this.setState({ formField })
                    }}
                    checked={formField.is_unique}
                    aria-labelledby="neat-label"
                    onColor="#333333"
                    offColor="#F3F6F9"
                    checkedIcon={null}
                    uncheckedIcon={null}
                    className="c-switch"
                  />
                </div>
              </div>
            </>
          }

          {
            form.type === "custom" &&
            <div className="form-group row align-items-center">
              <label className="col-lg-3 col-form-label">Unique</label>
              <div className="col-lg-3">
                <Switch
                  className="react-switch"
                  onChange={checked => {
                    formField.is_unique = checked
                    this.setState({ formField })
                  }}
                  checked={formField.is_unique}
                  aria-labelledby="neat-label"
                  onColor="#333333"
                  offColor="#F3F6F9"
                  checkedIcon={null}
                  uncheckedIcon={null}
                  className="c-switch"
                />
              </div>
            </div>
          }

          {
            formField.options &&

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Options</label>
              <div className="col-lg-9">
                <SmartList
                  title="Options"
                  items={formField.options}
                  itemName="Option"
                  minimumItems={2}
                  value={item => item.text}
                  disabled={item => false}
                  onChange={(value, item, index) => {
                    formField.options[index].text = value
                    this.setState({ formField })
                  }}
                  onUpdated={options => {
                    formField.options = options
                    this.setState({ formField })
                  }}
                  onAddItemPressed={() => {
                    formField.options.push({})
                    this.setState({ formField })
                  }}
                />
              </div>
            </div>
          }

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._handleUpdateFormItem()}
          >
            Update
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
