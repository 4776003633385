import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Div from '../layouts/Div'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"
import General from "../../../utils/General"

import ImageEdit from '../common/ImageEdit';
import Select from '../common/Select';
import AsyncSelect from '../common/AsyncSelect'

import ColorPicker from '../ColorPicker'

import ImagePickerModal from './ImagePickerModal'

const LOADING_GIF = Math.random() < 0.5 ? require('../../../assets/media/gifs/creating-website.gif') : require('../../../assets/media/gifs/creating-website.gif')

const CURRENCY_OPTIONS = [
  {
    label: "EUR",
    value: "EUR"
  },
  {
    label: "GBP",
    value: "GBP"
  },
  {
    label: "USD",
    value: "USD"
  }
]

export default class CreatePartnerModal extends React.Component {
  constructor(props){
    super(props)
    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      data: {
        website: {
          status: "live",
          primary_color: "#3d85c6",
          recurring: 0,
          target_website_id: props.targetWebsite ? props.targetWebsite.id : null
        },
      }
    }
  }

  _handleChange(e) {
    let { data } = this.state
    data[e.target.name] = e.target.value
    this.setState({ data })
  }

  _handleWebsiteChange(e) {
    let { data } = this.state
    data.website[e.target.name] = e.target.value
    this.setState({ data })
  }

  _isFormValid(){
    let {
      first_name,
      last_name,
      email,
      password,
      phone_number,
      website,
      currency,
      company_name,
    } = this.state.data

    let {
      slug,
      banner_image_id,
      primary_color
    } = website

    let error = null

    if(!company_name){
      error = "Please enter a valid partner name"
    }
    else if(!slug || this.state.errorSlug){
      error = "Please enter a valid xyz url"
    }
    else if(!first_name){
      error = "Please enter a first name"
    }
    else if(!last_name){
      error = "Please enter a last name"
    }
    else if(!email){
      error = "Please enter an email"
    }
    else if(!phone_number){
      error = "Please enter a phone number"
    }
    else if(!password || password.length < 6){
      error = "Please enter a valid password"
    }
    else if(!currency){
      error = "Please select a currency"
    }
    else if(!primary_color){
      error = "Please select a brand color"
    }
    else if(!banner_image_id){
      error = "Please select an image"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _setSlug = General.debounce(() => {
    let {
      data
    } = this.state

    let website = data.website

    if(website.slug){
      return
    }

    Backend.slugify(website.name)
    .then(response => {
      let errorSlug = false
      if(website.slug && response.slug != website.slug){
        errorSlug = true
      }
      if(!website.slug){
        website.slug = response.slug
      }
      data.website = website
      this.setState({ errorSlug, data })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }, 500)

  _checkSlug(){
    let {
      data
    } = this.state

    let website = data.website

    Backend.slugify(website.slug)
    .then(response => {
      if(response.slug != website.slug){
        this.setState({ errorSlug: true })
      }
      else{
        this.setState({ errorSlug: false })
      }
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _onCreateClicked(){
    let {
      data
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.createPartner(data)
    .then(partner => {
      this.setState({
        partner,
        created: true,
        loading: false
      })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _onEditWebsiteClicked(website){

    this.props.onCancel()
    this.setState({ loadingHash: true })
    let url = Website.getSiteUrl(website) + "?edit=true"
    let editWebsiteTab = window.open();
    Backend.createLoginHash(website)
    .then(hash => {
      editWebsiteTab.location.href = url+"&h="+hash.value
      this.setState({ loadingHash: false })
    })
    .catch(error => {
      editWebsiteTab.location.href = url
      this.setState({ loadingHash: false })
    })
  }

  _renderContent(){
    let {
      loading,
      created
    } = this.state

    if(created){
      return this._renderCreated()
    }
    else{
      return this._renderForm()
    }
  }

  _renderLoading(){
    return (
      <Modal.Body>
        <div className="text-center my-auto">
          <img src={ LOADING_GIF } alt="Laoding" height="300px"/>
        </div>
      </Modal.Body>
    )
  }

  _renderCreated(){
    let {
      partner,
    } = this.state

    return (
      <>
        <Modal.Body>
          <div className="text-center my-auto">
            <h1>Success!</h1>
            <p>Partner was successfully created</p>
          </div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Close
          </button>

          <a
            href="#"
            className={`btn btn-primary`}
            onClick={e => {
              e.preventDefault()
              this._onEditWebsiteClicked(partner.company.websites[0])
            }}
          >
            View
          </a>

        </Modal.Footer>
      </>
    )
  }


  _renderForm() {
    let {
      loading,
      data,
      errorSlug,
      imageLoading,
      target_website_id,
    } = this.state

    let website = data.website

    let className = "input-group-append-bg-dark "

    if(website.slug){
      className += errorSlug ? "input-group-append-bg-dark-error" : "input-group-append-bg-green"
    }

    let image = website.image

    return (
      <>
        <Modal.Body>
          <Div
            disabled={loading}
            spinner={true}
          >

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Partner Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="company_name"
                  className="form-control form-control-solid"
                  value={ data.website.company_name }
                  onChange={e => {
                    data.company_name = e.target.value
                    data.website.name = data.company_name
                    this.setState({ data })
                  }}
                  onBlur={e => {
                    if(!website.slug){
                      this._setSlug()
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">{window.General.Branding.Name} URL</label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <input
                    type="text"
                    name="slug"
                    className="form-control form-control-solid"
                    value={ website.slug }
                    onChange={e => this._handleWebsiteChange(e)}
                    onBlur={e => {
                      this._checkSlug()
                    }}
                  />
                  <div class={`input-group-append ${className}`}>
                    <span class="input-group-text">.{window.General.Branding.Domain} </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">First Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="first_name"
                  className="form-control"
                  value={ data.first_name }
                  onChange={e => this._handleChange(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Last Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="last_name"
                  className="form-control"
                  value={ data.last_name }
                  onChange={e => this._handleChange(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Email</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={ data.email }
                  onChange={e => this._handleChange(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Phone</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="tel"
                  name="phone_number"
                  className="form-control"
                  value={ data.phone_number }
                  onChange={e => this._handleChange(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Password</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={ data.password }
                  onChange={e => this._handleChange(e)}
                />
              </div>
            </div>

            <div class="c-separator form-group row mt-4 align-items-center text-center">
              <div class="col-3">
                <hr/>
              </div>
              <div class="col-6">
                <span>
                  <strong>
                    Financials
                  </strong>
                </span>
              </div>
              <div class="col-3">
                <hr/>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Currency</label>
              <div className="col-lg-9 my-auto">
                <Select
                  value={CURRENCY_OPTIONS.find(option => option.value === data.currency)}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  onChange={option => {
                    data.currency = option.value
                    this.setState({ website })
                  }}
                  options={CURRENCY_OPTIONS}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Monthly Licence</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="number"
                  step={"0.01"}
                  name="recurring"
                  className="form-control form-control-solid"
                  value={ data.website.monthly_license ? data.website.monthly_license / 100 : "" }
                  placeholder="€0.00"
                  onChange={e => {
                    data.website.monthly_license = Math.round(e.target.value * 100)
                    this.setState({data})
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Management & Running Fee (%)</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="commission"
                  className="form-control form-control-solid"
                  defaultValue={ data.website.commission ? data.website.commission * 100 : "" }
                  placeholder="0%"
                  onChange={e => {
                    data.website.commission = +(e.target.value / 100.0)
                    data.website.commission  = Math.round((data.website.commission  + Number.EPSILON) * 10000) / 10000

                    this.setState({ data })
                  }}
                />
              </div>
            </div>

            <div class="c-separator form-group row mt-4 align-items-center text-center">
              <div class="col-3">
                <hr/>
              </div>
              <div class="col-6">
                <span>
                  <strong>
                    Branding
                  </strong>
                </span>
              </div>
              <div class="col-3">
                <hr/>
              </div>
            </div>

            <ColorPicker
              title={ "Brand Colour" }
              color={ data.website.primary_color }
              onColorSelected={color => {
                data.website.primary_color = color
                this.setState({ data })
              }}
            />

            <div className="form-group mt-5">
              <label>Logo</label>
              <div className="mt-3">
                <ImageEdit
                  image={data.logo}
                  type="photo"
                  hidePreview={false}
                  aspectRatio={1.5}
                  onUpdated={image => {
                    data.logo = image
                    this.setState({ data })
                  }}
                />
              </div>
            </div>

            <div class="form-group">
              <label>Favicon</label>
              <div className="mt-3">
                <ImageEdit
                  image={data.website.favicon}
                  type="photo"
                  cropImage={true}
                  hidePreview={false}
                  aspectRatio={1}
                  onUpdated={image => {
                    data.website.favicon = image
                    this.setState({ data })
                  }}
                />
              </div>
            </div>

            <div class="form-group fv-plugins-icon-container">
              <label className="mb-4">Main Banner Image</label>
               <div
                className="form-control form-control-solid text-center p-0 d-flex justify-content-center align-items-center cursor-pointer choose-image"
                style={{ height: 300, overflow: 'hidden' }}
                onClick={() => this.setState({ showImagePicker: true })}
               >
                { !image &&
                  <>
                    <div className="choose-image-overlay"/>
                    <div className="bg-text">
                      <p className="my-auto">Select Random Image</p>
                    </div>
                  </>
                }
                { (image || imageLoading) &&
                  <div className="w-100 shine shine-overlay">
                    { image &&
                      <>
                      <div className="uploaded-image" style={{ backgroundImage: "url("+image.original+")" }} ></div>
                      </>
                    }
                  </div>
                }
               </div>
            </div>

          </Div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._onCreateClicked()}
          >
            { this.props.createButtonText }
          </button>

        </Modal.Footer>
      </>
    )
  }

  render() {
    let {
      show,
      created,
      loading,
      data,
      showImagePicker
    } = this.state

    let title = loading ? "Creating Partner..." : "Create Partner"
    if(created){
      title = "Partner Created"
    }

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onCancel()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{ title }</Modal.Title>
          </Modal.Header>

          { this._renderContent() }

        </Modal>
        <ImagePickerModal
          show={showImagePicker}
          onSelected={image =>  {
            this.setState({ imageLoading: true, showImagePicker: false })
            Backend.addImage(image)
            .then(image => {
              data.website.image = image
              data.website.banner_image_id = image.id
              this.setState({ data, imageLoading: false })
            })
            .catch(error => {
              Notify.error(error.message)
              this.setState({ imageLoading: false })
            })
          }}
          onHide={() => this.setState({ showImagePicker: false })}
        />
      </>
    )
  }
}

CreatePartnerModal.defaultProps = {
    loading: false,
    createButtonText: "Create"
}
