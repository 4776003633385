import React from "react"

import moment from 'moment'

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Website from '../../../utils/Website'
import Currency from '../../../utils/Currency'

import ConfirmModal from '../../components/modal/ConfirmModal'
import TransferModal from '../../components/modal/TransferModal'
import CreateWebsiteModal from '../../components/modal/CreateWebsiteModal'

const ICON = (
  <g stroke="none" strokewidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z" fill="#000000"></path>
      <path d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
  </g>
)

export default class CompaniesTable extends React.Component {

  constructor(props){
    super(props)

    let endpoint = window.Api.Companies
    this.state = {
      endpoint,
      loading: true,
      filters: [],
      selectedFilter: null,
    }

    this.table = React.createRef()
  }

  componentDidMount(){

  }

  _getColumns() {
    let columns = [
      {
        Header: 'Created',
        id: 'created_at',
        accessor: company => moment(company.created_at).format('DD MMM YYYY')
      },
      {
        Header: 'Name',
        id: 'name',
        Cell: rowInfo => {
          let company = rowInfo.original

          let email = null
          if(company.company_members.length > 0){
            email = company.company_members[0].user.email
          }
          return (
            <div className="kt-user-card-v2">
							<div className="kt-user-card-v2__details">
              	<span className="kt-user-card-v2__name">
                  { company.name}
                </span>
                { email &&
                  <a href={`mailto:${email}`} className="kt-user-card-v2__email kt-link">{ email }</a>
                }
              </div>
            </div>
          )
        }
      },
    ]

    if(!AuthManager.isPartner()){
      columns.push({
        Header: 'Partner',
        id: 'company.associate.name',
        Cell: rowInfo => {
          let company = rowInfo.original
          if(company.associate){
            return company.associate.company.name
          }
          if(company.partner){
            return company.name
          }
          return "-"
        },
      })
    }

    return [
      ...columns,
      {
        Header: 'Source',
        accessor: "source",
        Cell: rowInfo => {
          let company = rowInfo.original

          return (
            <div className="kt-user-card-v2">
							<div className="kt-user-card-v2__details">
              	<span className="kt-user-card-v2__name">
                  { company.source }
                </span>
                { company.referrer &&
                  <a className="kt-user-card-v2__email kt-link">{ company.referrer }</a>
                }
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Payment',
        id: 'subscription',
        Cell: rowInfo => {
          let company = rowInfo.original
          let label = "Free"
          let type = "success"

          if(!company.subscription){
            label = "No"
            type = "light"
          }
          else if(company.subscription.amount > 0){
            label = Currency.toCurrency("€", company.subscription.amount)
            type = "success"
            if(!company.subscription.active){
              type = "warning"
            }
            return (
              <span
                className={`kt-badge kt-badge--${type} kt-badge--inline kt-badge--pill`}
              >
                  { label }
              </span>
            )
          }

          return label
        }
      },

      {
        Header: 'Coupon',
        id: 'premium',
        Cell: rowInfo => {
          let company = rowInfo.original
          let label = "No"
          let type = "light"

          if(company.subscription && company.subscription.coupon){
            label = company.subscription.coupon.name
            type = "success"
          }

          return label
        }
      },
    ]
  }

  render() {
    let {
      filters,
      loading,
      endpoint,
      showTransferModal,
      selectedWebsite,
      showWebsiteModal,
      isWebsiteArchiving,
      showWebsiteArchiveModal,
      showCreateWebsiteModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          title={ this.props.title }
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showPagination={this.props.showPagination}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
          exportButtonsEnabled={false}
          icon={ICON}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
        />

        <ConfirmModal
          show={showWebsiteArchiveModal}
          company={selectedWebsite}
          loading={isWebsiteArchiving}
          message="Archiving the company will hide it on the site"
          cancelButtonText="No, cancel"
          confirmButtonText="Yes, archive it"
          onCancel={() => this.setState({ selectedWebsite: null, showWebsiteArchiveModal: false})}
          onConfirm={() => this._archiveWebsite(selectedWebsite)}
        />

        <TransferModal
          show={showTransferModal}
          company={selectedWebsite}
          onCancel={() => this.setState({ selectedWebsite: null, showTransferModal: false})}
          onTransferred={() => {
            this.setState({ selectedWebsite: null, showTransferModal: false})
          }}
        />

        <CreateWebsiteModal
          show={showCreateWebsiteModal}
          targetWebsite={selectedWebsite}
          createButtonText="Duplicate"
          onCancel={() => this.setState({ showCreateWebsiteModal: false })}
          onCreated={company => {
            this.setState({ selectedWebsite: null, showCreateWebsiteModal: false })
          }}
        />
      </>
    )
  }
}

CompaniesTable.defaultProps = {
  title: "Companies",
  demo: false,
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
}
