import React from "react"

import Div from "../layouts/Div"

import PurchaseDomainModal from "../modal/PurchaseDomainModal"
import Backend from "../../../utils/Backend"
import Notify from "../../../utils/Notify"
import AuthManager from "../../../utils/AuthManager"
import Currency from "../../../utils/Currency"

export default class BuyDomainSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: {},
      domains: [],
      loading: false,
      website: AuthManager.currentWebsite,
    }
  }

  _searchDomains(e){
    let {
      data
    } = this.state

    e.preventDefault()

    if(data.name.indexOf(".") == -1){
      data.name += ".com"
    }
    this.setState({ loading: true })
    Backend.searchDomains(data)
    .then(domains => {
      this.setState({
        domains,
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _renderDomains(){
    let {
      domains
    } = this.state

    return domains.map(domain => {
      if(domain.prices.length == 0){
        return null
      }

      if(!domain.available){
        return (
          <div className="row mt-5">
            <div className="col-8">
              <h3 style={{ color: '#666'}}><strike>{domain.name}</strike></h3>
              <span  style={{ color: '#666'}}>Domain is no longer available</span>
            </div>
            <div className="col-4 text-right">

            </div>
          </div>
        )
      }
      let price = domain.prices[0].price
      if(domain.is_premium){
        price = domain.premium_price
      }

      return (
          <div className="row mt-5">
            <div className="col-8">
              <h3 style={{ color: '#333'}}>{domain.name}</h3>
            </div>
            <div className="col-4 text-right">
              <a
                href="#"
                className="btn btn-outline-primary"
                disabled={!domain.available}
                onClick={() => this.setState({ selectedDomain: domain, showPurchaseDomainModal: true, })}
              >
                {Currency.format(price, "eur")} / yr
              </a>
            </div>
          </div>
      )
    })
  }

  render() {
    let {
      data,
      loading,
      selectedDomain,
      showPurchaseDomainModal
    } = this.state

    return (
      <Div
        className="kt-wizard-v2__content"
        data-ktwizard-type="step-content"
        data-ktwizard-state="current"
        spinner={true}
        disabled={loading}
      >
        <div className="kt-heading kt-heading--md">Buy Domain</div>
        <div className="kt-form__section kt-form__section--first">
          <div className="kt-wizard-v2__form">
            <div className="row">
              <div className="col-8">
                <div className="form-group mb-0">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ data.name }
                    onChange={e => this._handleChange(e)}
                    placeholder="mywebsite.com"
                  />
                </div>
              </div>
              <div className="col-4">
                <button
                  className="btn btn-brand btn-md btn-wide kt-font-bold kt-font-transform-u"
                  style={{ height: '100%'}}
                  onClick={(e) => this._searchDomains(e)}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          { this._renderDomains() }
        </div>


        <PurchaseDomainModal
          show={showPurchaseDomainModal}
          domain={selectedDomain}
          onCancel={() => this.setState({ selectedDomain: null, showPurchaseDomainModal: false })}
          onPurchasedDomain={domain => this.props.onPurchasedDomain(domain)}
        />
      </Div>
    )
  }
}
