import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

const DEFAULT_IMAGE = require("../../../assets/media/icons/svg/humans/custom-10.svg")


const TYPES = {
  warning: 'alert-custom alert-warning alert-white',
  info: 'alert-custom alert-secondary',
  dark: "alert-custom alert-dark",
  note: "alert-custom alert-info"
}
export default class Alert extends React.Component {

  render(){
    return (
      <div class={`alert ${TYPES[this.props.type]} mb-4`} role="alert">
          <div class="alert-icon"><i class={this.props.iconClassName}></i></div>
          <div class="alert-text ">{ this.props.text }</div>
          { this.props.closable &&
            <div class="alert-close">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true"><i class="la la-close text-white"></i></span>
                </button>
            </div>
          }
          { this.props.cta &&
            <div class="alert-button">
                <button type="button" onClick={() => this.props.onClick()}>
                  { this.props.cta }
                </button>
            </div>
          }
      </div>
    )
  }
}

Alert.defaultProps = {
  text: "Placeholder Text",
  iconClassName: "flaticon-warning",
  closable: true,
  type: "warning"
}
