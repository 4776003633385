import React from "react"

export default class MobileHeader extends React.Component {
  render() {
    return (
      <div id="kt_header_mobile" className="kt-header-mobile  kt-header-mobile--fixed ">
      	<div className="kt-header-mobile__logo">
      		<a href="/dealer">
      			<img alt="Logo" src={require('../../assets/media/logos/logo-dark.png')} height="30px" />
      		</a>
      	</div>
      	<div className="kt-header-mobile__toolbar">
      		<button className="kt-header-mobile__toggler kt-header-mobile__toggler--left" id="kt_aside_mobile_toggler"><span></span></button>
      		<button className="kt-header-mobile__topbar-toggler" id="kt_header_mobile_topbar_toggler"><i className="flaticon-more"></i></button>
      	</div>
      </div>
    )
  }
}
