import React, { Component } from "react";

import moment from "moment";

export class ConversationListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conversation: props.conversation,
    };
  }

  _renderConversationInfo(conversation) {
    let lastMessageText = conversation.last_message?.text.slice(0, 20) || "";

    let title = conversation.website?.name;

    return (
      <>
        <div
          className={`kt-widget__item ${
            this.props.selected  && "selected"
          }`}
          onClick={() => this.props.onClick(conversation)}
        >
          <div className="kt-widget__info">
            <div className="kt-widget__section">
              <a href="javascript:;" className="kt-widget__username">
                {title}
              </a>
              {conversation.visitor?.status === "online" && (
                <span className="kt-badge kt-badge--success kt-badge--dot"></span>
              )}
            </div>
            <span className="kt-widget__desc">{lastMessageText}</span>
          </div>
          <div className="kt-widget__action">
            { conversation.last_message &&
              <span className="kt-widget__date">
                {moment(conversation.last_message.created_at).fromNow()}
              </span>
            }
          </div>
        </div>
      </>
    );
  }

  render() {
    const { conversation } = this.state;

    return this._renderConversationInfo(conversation);
  }
}

export default ConversationListItem;
