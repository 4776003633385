import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import Alert from './Alert'

import Time from '../../../utils/Time'
import AuthManager from '../../../utils/AuthManager'

export default class PropagationAlert extends React.Component {
  constructor(props){
    super(props)

    let fallbackUrl = props.fallbackUrl ? props.fallbackUrl : '/account'
    this.state = {
      domain: AuthManager.currentWebsite.domain
    }
  }

  componentDidMount(){
    if(!this.state.domain){
      return
    }
    setInterval(() => {
      this.setState({ updated: true })
    }, 1000)
  }

  render(){
    let {
      domain
    } = this.state
    if(!domain || domain.ssl || domain.type === "transfer"){
      return null
    }
    let expectedSSLIssuedAt =  moment(domain.created_at).add('hours', 1)
    let text = "Congratulations on your new domain! The internet robots are working hard to get it setup and working for you."
    if(expectedSSLIssuedAt.isAfter()){
      text += ` They should be finished in ${Time.formatFromNow(expectedSSLIssuedAt)}`
    }
    else{
      text += " They are taking longer than usual but should be finished soon!"
    }

    return (
      <Alert
        text={text}
      />
    )
  }
}
