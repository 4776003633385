import React from 'react'

import AuthManager from '../utils/AuthManager'
import Email from '../utils/Email'

const LOGO = require('../assets/media/logos/logo-dark.png')
const LOGIN_VISUAL = require('../assets/media/svg/illustrations/login-visual-1.svg')

const MODE_SIGNUP = "signup"
const MODE_FORGOT_PASSWORD = "forgot_password"
const MODE_RESET_PASSWORD = "reset_password"

export default class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      isLoading: false,
      mode: MODE_SIGNUP,
    }
  }

  _isFormValid() {
    let { email, password, confirmPassword, mode, code } = this.state

    if (!Email.isValid(email)) {
      this.setState({ error: 'Please enter a valid email address' })
      return false
    }

    if(mode == MODE_RESET_PASSWORD){
      if(!code){
        this.setState({ error: 'Please enter a valid code' })
        return false
      }
      if(password.length < 6){
        this.setState({ error: 'Password must be at least 6 characters' })
        return false
      }
      if(password != confirmPassword){
        this.setState({ error: 'Passwords do not match' })
        return false
      }
    }

    return true
  }

  _handleLogInClicked(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password } = this.state

    this.setState({ isLoading: true })
    AuthManager.login(email, password)
    .then(() => {
      let url = "/"
      window.location = url
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })
  }

  _handleRequestResetPassword(e){
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, confirmPassword } = this.state

    this.setState({ isLoading: true })
    AuthManager.requestResetPassword(email)
    .then(() => {
      this.setState({
        isLoading: false,
        mode: MODE_RESET_PASSWORD,
        password: "",
        confirmPassword: "",
      })
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })
  }

  _handleResetPassword(e){
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, code } = this.state

    this.setState({ isLoading: true })
    console.log("999999")
    AuthManager.resetPassword(email, password, code)
    .then(() => {
      this.setState({
        isLoading: false,
        mode: MODE_SIGNUP,
        email: "",
        password: "",
        confirmPassword: "",
      })
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })

  }

  _renderError() {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    )
  }

  render() {
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      code,
    } = this.state

    let loginAsideClassName = "login-aside d-flex flex-column flex-row-auto pt-lg-40 pt-10";

    return (
      <>

        <div className="metr_v702 d-flex flex-column flex-root">
          <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white v1 v2" id="kt_login">

            {/* begin : Login Aside */}
            <div className={loginAsideClassName}>
              <div className="d-flex flex-column-auto flex-column pl-25 pr-25 text-center">
                <a href="#" className="mb-10">
                  <img src={ window.General.Branding.Logo } alt="Logo" className="max-h-100px" alt="" />
                </a>
                <h3 className="font-weight-bolder text-center font-size-h4 font-size-h1-lg mb-10" style={{ color: '#986923' }}>Make Any Type Of Site</h3>
              </div>
              <div className="d-none aside-img d-md-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" style={{ backgroundImage:`url(${LOGIN_VISUAL})` }}></div>
            </div>
            {/* end : Login Aside */}

            {/* <!--begin::Content--> */}
            <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden w-100">

              <div className="d-flex flex-column-fluid flex-center">

                {/* <!--begin::Signin--> */}
                <div className="login-form login-signin"
                  style={{
                    display: mode == MODE_SIGNUP ? "block" : "none"
                  }}
                >
                  <form className="form" novalidate="novalidate">

                    <div className="pb-13 pt-lg-0 pt-5">
                      <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Sign In To {window.General.Branding.Name}</h3>
                    </div>

                    { this._renderError() }

                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">Email</label>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="text"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={e => this.setState({ email: e.target.value, error: null })}
                        tabIndex={1}
                      />
                    </div>

                    <div className="form-group">
                      <div className="d-flex justify-content-between mt-n5">
                        <label className="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                        <a href="javascript:;" className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                          onClick={() => this.setState({ email: "", mode: MODE_FORGOT_PASSWORD, error: null})}
                        >Forgot Password ?</a>
                      </div>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={e => this.setState({ password: e.target.value, error: null })}
                        tabIndex={2}
                      />
                    </div>

                    <div className="pb-lg-0 pb-5">
                      <button
                        className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                        onClick={e => this._handleLogInClicked(e)}
                        disabled={isLoading}
                      >
                        { isLoading  ? "Loading... " : "Sign In" }
                      </button>

                    </div>

                  </form>
                </div>
                {/* <!--end::Signin-->

                <!--begin::Forgot--> */}
                <div className="login-form login-forgot"
                  style={{
                    display: mode == MODE_FORGOT_PASSWORD ? "block" : "none"
                  }}
                >
                  <form className="form" novalidate="novalidate">

                    <div className="pb-13 pt-lg-0 pt-5">
                      <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Forgotten Password ?</h3>
                      <p className="text-muted font-weight-bold font-size-h4">Enter your email to reset your password</p>
                    </div>

                    { this._renderError() }

                    <div className="form-group">

                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                        type="email"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={e => this.setState({ email: e.target.value, error: null })}
                      />
                    </div>

                    <div className="form-group d-flex flex-wrap pb-lg-0">

                      <button
                        className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                        onClick={e => {
                          e.preventDefault()
                          this.setState({ email: "", password: "", mode: MODE_SIGNUP, error: null })
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                        onClick={e => this._handleRequestResetPassword(e)}
                        disabled={isLoading}
                      >
                        { isLoading  ? "Loading... " : "Request" }
                      </button>

                    </div>

                  </form>
                </div>
                {/* <!--end::Forgot--> */}

                {/* <!--begin::reset--> */}
                <div className="login-form login-reset"
                  style={{
                    display: mode == MODE_RESET_PASSWORD ? "block" : "none"
                  }}
                >
                  <form className="form" novalidate="novalidate">

                    <div className="pb-13 pt-lg-0 pt-5">
                      <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Reset Password</h3>
                      <p className="text-muted font-weight-bold font-size-h4">A verification code was sent to your email, please enter it below along with your new password</p>
                    </div>

                    { this._renderError() }

                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">Code</label>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="text"
                        placeholder="Code"
                        name="code"
                        autoComplete="off"
                        value={code}
                        onChange={e => this.setState({ code: e.target.value, error: null })}
                      />
                    </div>

                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">Password</label>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="password"
                        placeholder="Password"
                        name="password"
                        autoComplete="off"
                        value={password}
                        onChange={e => this.setState({ password: e.target.value, error: null })}
                      />
                    </div>

                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">Confirm Password</label>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="password"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        autoComplete="off"
                        value={confirmPassword}
                        onChange={e => this.setState({ confirmPassword: e.target.value, error: null })}
                      />
                    </div>

                    <div className="form-group d-flex flex-wrap pb-lg-0">

                      <button
                        className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                        onClick={e => {
                          e.preventDefault()
                          this.setState({ email: "", password: "", mode: MODE_SIGNUP, error: null })
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                        onClick={e => this._handleResetPassword(e)}
                        disabled={isLoading}
                      >
                        { isLoading  ? "Loading... " : "Update Password" }
                      </button>

                    </div>

                  </form>
                </div>
                {/* <!--end::reset--> */}

              </div>
              {/* <!--end::Content body--> */}

            </div>
            {/* end::Content */}

          </div>
        </div>

        <style dangerouslySetInnerHTML={{ __html: `
              :root {
                --pkg_primary_color: #000000;
                --pkg_title_color: #000000;
                --pkg_cta_background_color: #333333;
                --pkg_cta_text_color: #ffffff;
                --login_aside_bg_color: #f2c98a;
              }
          `}}
        />

      </>
    )
  }
}
