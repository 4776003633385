import React from "react"

import moment from 'moment'

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Currency from '../../../utils/Currency'


const ICON = (
  <g stroke="none" strokewidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z" fill="#000000"></path>
      <path d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
  </g>
)

const STATUSES = [
  {
    label: "All",
    value: "all"
  },
  {
    label: "Active",
    value: "true"
  },
  {
    label: "Inactive",
    value: "false"
  }
]

export default class DomainsTable extends React.Component {

  constructor(props){
    super(props)

    let endpoint = window.Api.Domains
    this.state = {
      endpoint,
      loading: true,
      filters: STATUSES,
      selectedFilter: null,
    }

    this.table = React.createRef()
  }

  _getColumns() {
    return [
      {
        Header: 'Created',
        id: 'created_at',
        accessor: website => moment(website.created_at).format('DD MMM YYYY')
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Cost',
        id: "namecheap_amount",
        accessor: domain => Currency.toCurrency("$", domain.namecheap_amount),
      },
      {
        Header: 'Paid',
        id: "charge_amount",
        accessor: domain => Currency.toCurrency("$", domain.charge_amount),
      },
      {
        Header: 'Fee',
        id: "stripe_fee",
        accessor: domain => Currency.toCurrency("$", domain.stripe_fee),
      },
      {
        Header: 'SSL',
        id: "ssl",
        accessor: domain => domain.ssl ? "Yes" : "No",
      },
      {
        Header: 'Expiry Date',
        id: 'expires_at',
        accessor: domain => moment(domain.expires_at).format('DD MMM YYYY')
      },
      {
        Header: 'Renewal Status',
        id: 'renewal_status',
        Cell: rowInfo => {
          let domain = rowInfo.original
          let label = "Auto"
          let type = "success"

          if(domain.renewal_status !== "auto"){
            label = "Failed"
            type = "warning"
          }

          return (
            <span
              className={`kt-badge kt-badge--${type} kt-badge--inline kt-badge--pill`}
            >
                { label }
            </span>
          )
        }
      },
    ]
  }

  render() {
    let {
      filters,
      loading,
      endpoint,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          title={ this.props.title }
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showPagination={this.props.showPagination}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
          exportButtonsEnabled={false}
          filters={filters}
          searchFilterName="active"
          icon={ICON}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
        />
      </>
    )
  }
}

DomainsTable.defaultProps = {
  title: "Domains",
  demo: false,
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
}
