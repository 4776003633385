import React from 'react'


import WebsitesTable from './components/tables/WebsitesTable'

export default class PartnerSites extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    return (
      <div className="row">
        <WebsitesTable
          mode="partner"
          title="Partner Sites"
        />
      </div>
    )
  }
}
