import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import EngagementCard from './EngagementCard'

import AuthManager from '../../../utils/AuthManager'
import Website from '../../../utils/Website'

export default class DomainEngagementCard extends React.Component {

  render(){

    let domain = Website.getNativeDomain(AuthManager.currentWebsite).replace("http://", "")

    return (
      <EngagementCard
        { ...this.props }
        title="What Domain Name?"
        contentContainerClassName="col-12"
        renderContent={() => {
          return (
            <>
              <p>You have 3 options to choose from.</p>
              <p>01 - Use the free {window.General.Branding.Name} domain name that you have - {domain}.
              <br/>02 - If you previously purchased a domain name before, you can use that.
              <br/>03 - Or, we'll help you buy a new domain name of your choice.
              </p>
            </>
          )
        }}
      />
    )
  }
}
