import React from 'react'
import Card from './Card'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import ListSettingsModal from "../modal/ListSettingsModal";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

export default class List extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      list: props.list,
      cards: props.cards,
      index: props.index,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderCards() {
    let { list } = this.props

    return (
      <Droppable droppableId={list.id.toString()} type="card">
        {(provided, snapshot) => (
          <div
            className={
              snapshot.isDraggingOver
                ? 'card-list-is-dragging kanban-drag'
                : 'card-list kanban-drag'
            }
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div className="kanban-drag-list">
              {
                list.cards.length === 0 ?
                <p>You haven't added a lead yet!</p> :
                list.cards.map((card, index) => {
                  return (
                    <Card
                      key={card.id}
                      index={index}
                      card={card}
                      onClicked={() => this.props.onCardClicked(card, index, list)}
                      render={() => this.props.renderCard(card, index, list)}
                    />
                  )
                })
              }
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    )
  }

  _renderSettingsModal(){
    let {
      list,
      showListModal
    } = this.state

    return (
      showListModal &&
      <ListSettingsModal
        show={showListModal}
        list={list}
        onHide={() => this.setState({showListModal: false})}
        updateListSettings={(list) => {
          this.setState({showListModal: false})
          this.props.onSettingsUpdated(list)
        }}
      />
    )
  }

  render() {
    let { list, cards, index } = this.state

    let deleteClassName = list.cards.length > 0 ? 'disabled-link' : ''

    return (
      <>
        <Draggable draggableId={list.id.toString()} key={list.id.toString()} index={index}>
          {(provided) => (
            <div
              className="column-container kanban-board"
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <header
                className={
                  'column-title kanban-board-header d-flex align-items-center justify-content-between ' +
                  list.class
                }
                {...provided.dragHandleProps}
                style={{ backgroundColor: list.color + '33' }} // 20% opacity
              >
                { this.props.renderHeader(list, index, cards)}

                <a 
                  href="javascript:void(0);"
                  class="label label-rounded label-success fs-16 font-weight-bold hover-w-7"
                  style={{ backgroundColor: list.color + 'cc' }} // 80% opacity
                  data-toggle="dropdown">
                  <i className="la la-cog px-2"></i>
                </a>
                <div className="dropdown-menu">
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => {
                      this.setState({showListModal: true, list})
                    }}
                  >
                    Settings
                  </a>
                  <a
                    className={`dropdown-item ${deleteClassName}`}
                    href="#"
                    onClick={() => this.props.onListDeleted(list)}
                  >
                    Delete
                  </a>
                </div>
                
                <a
                  href="javascript:void(0);"
                  class="label label-rounded label-success fs-16 font-weight-bold hover-w-7"
                  style={{ backgroundColor: list.color + 'cc' }} // 80% opacity
                  onClick={() => this.props.onAddCardClicked(list)}
                >
                  <i className="la la-plus px-2"/>
                </a>

              </header>
              {this._renderCards()}
            </div>
          )}
        </Draggable>
        {this._renderSettingsModal()}
      </>
    )
  }
}

List.defaultProps = {}
