import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import PackagesTable from './components/tables/PackagesTable'


export default class Packages extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    return (
      <div className="row">
        <PackagesTable />
      </div>
    )
  }
}
