import React from 'react'

import {Portal} from 'react-portal';

import Reports from './Reports'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'
import Notify from '../utils/Notify'

export default class PartnerDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      partner: null,
      loading: true
    }
  }

  componentDidMount(){
    Backend.getPartner(this.props.match.params.partner_id)
    .then(partner => {
      this.setState({ partner, loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  render() {
    let {
      partner,
      loading
    } = this.state

    if(loading || !partner){
      return null
    }

    return (
      <>
        <Portal node={document && document.getElementById('kt_header_menu_title')}>
          { partner.company.name }
        </Portal>
        <Reports
          partner={partner}
        />
      </>
    )
  }
}
