import React from 'react'

import SVG, { Props as SVGProps } from 'react-inlinesvg';

// Render a standard jpg/png/gif etc.. in an imgg and an svg in an scg
export default class Image extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      className: props.className,
      src: props.src,
      alt: props.alt,
      type: props.type,
      primaryColor: props.primaryColor
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }


  render(){
    let {
      src,
      alt,
      type,
      className,
      primaryColor
    } = this.state


    let style = {}

    if(this.props.onClick){
      style = { cursor: "pointer" }
    }

    console.log("222", src)

    if(type === "photo"){
      return (
        <img
          className={ className }
          alt={ alt }
          src={ src }
          onClick={() => {
            if(this.props.onClick){
              this.props.onClick()
            }
          }}
          style={style}
        />
      )
    }

    return (
      <SVG
        key={this.props.primaryColor}
        cacheRequests={true}
        description={alt}
        className={ className }
        loader={<span>Loading...</span>}
        onError={error => console.log(error.message)}
        onLoad={(src, hasCache) => console.log(src, hasCache)}
        preProcessor={code => code.replace(/#6c63ff/g, this.props.primaryColor)}
        src={src}
        style={style}
        uniquifyIDs={true}
        onClick={() => {
          if(this.props.onClick){
            this.props.onClick()
          }
        }}
      />
    )
  }
}

Image.defaultProps = {
  type: "photo",
  primaryColor: "#6c63ff",
  onClick: null
}
